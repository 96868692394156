import * as React from 'react';
import WrapContent from 'app/components/WrapContent/WrapContent';

export function HomePage() {
  return (
    <>
      <WrapContent></WrapContent>
    </>
  );
}
