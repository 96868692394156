// Update date: 09-05-2023
// Screen dashboard

import React, { useEffect, useState, createContext, useContext } from 'react';
import { TabPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/tab-panel';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import './styles.scss';
import useAxios from 'axios-hooks';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import { useApp } from 'app';

const Tab1 = React.lazy(() => import('./Tab1'));
const Tab2 = React.lazy(() => import('./Tab2'));
const Tab3 = React.lazy(() => import('./Tab3'));
const Tab4 = React.lazy(() => import('./Tab4'));
const Tab5 = React.lazy(() => import('./Tab5'));

const DashBoardContext = createContext({});

DashBoardContext.displayName = 'DashBoardContext';

export const useDashboardContext = () => {
  const context = useContext(DashBoardContext);
  return context;
};
export default function Statistic() {
  const { themePro }: any = useApp();
  const { t } = useDictionary({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataList, setDataList] = useState([]);
  const onSelectedIndexChange = e => {
    setSelectedIndex(e);
  };

  const [{ loading: loadingCommonDB }, refetchCommonDB] = useAxios(
    {
      url: '/core/common-code/type',
      data: { codeName: '', codeType: 'DB', parentCd: '' },
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );
  const [{ loading: loadingGetDashboard }, refetchGetDashboard] = useAxios(
    {
      url: '/user/chart/user-dash-board',
      method: 'POST',
    },
    { manual: true, autoCancel: true },
  );

  const loadingIndicator = (loading?: boolean) => {
    return {
      enabled: true,
      show: loading || false,
      text: `${t('Loading')}...`,
      font: {
        color: '#d48002',
        size: 14,
      },
    };
  };

  // Auto change tab
  useEffect(() => {
    const autoChangeTab = () => {
      setSelectedIndex(preSelectedIndex => {
        if (preSelectedIndex > 3) return 0;
        return preSelectedIndex + 1;
      });
    };
    const autoChangeTabInteval = setInterval(autoChangeTab, 2 * 60 * 1000);
    return () => clearInterval(autoChangeTabInteval);
  }, [selectedIndex]);

  // Fetch data
  useEffect(() => {
    const fetchData = async () => {
      const myPagePreferenceLocal = JSON.parse(
        localStorage.getItem('myPagePreference') || '{}',
      );
      try {
        const resDashboard = await refetchGetDashboard({
          data: {
            userId: myPagePreferenceLocal?.userId,
          },
        });
        const resCommonDb = await refetchCommonDB({});
        const dbRes = (resDashboard?.data?.data || '').split(', ');
        const dataBD = resCommonDb?.data?.data || [];
        const dashboard = dataBD.filter(o => dbRes.some(s => s === o?.code));
        setDataList(dashboard);
      } catch (error) {}
    };
    fetchData();
  }, []);

  // Get content tab
  const getContent = code => {
    switch (code) {
      case '1':
        return <Tab1 />;
      case '2':
        return <Tab2 />;
      case '3':
        return <Tab3 />;
      case '4':
        return <Tab4 />;
      case '5':
        return <Tab5 />;
      default:
        break;
    }
  };

  const value: any = {
    selectedIndex,
    loadingIndicator,
  };

  return (
    <DashBoardContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingCommonDB || loadingGetDashboard} />
        <BreadCrumbPremium
          title={t('Dashboard')}
          style={{ margin: '10px 0' }}
        />
        <TabPanel
          onItemsChange={e => {}}
          loop
          animationEnabled
          swipeEnabled
          deferRendering
          repaintChangesOnly
          showNavButtons
          selectedIndex={selectedIndex}
          onSelectedIndexChange={onSelectedIndexChange}
          className="bg-white dashboard-tabpanel-premium dashboard-loadindicator"
          height={themePro ? 'calc(100vh - 135px)' : 'auto'}
        >
          {dataList?.map((o: any) => (
            <Item title={t(o?.name)} key={o?.code}>
              {getContent(o?.code)}
            </Item>
          ))}
        </TabPanel>
      </React.Suspense>
    </DashBoardContext.Provider>
  );
}
