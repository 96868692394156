/*
 * 2081.F SMS Delivery
 * since 14/11/2022
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import useDictionary from 'hooks/useDictionary';
import React, { createContext, useContext, useEffect, useState } from 'react';

const ReportStore = React.lazy(() => import('./ReportStore'));
const ReturnPlan = React.lazy(() => import('./ReturnPlan'));
const DepositRequest = React.lazy(() => import('./DepositRequest'));
const CenterOrder = React.lazy(() => import('./CenterOrder'));
const BreadCrumbHeader = React.lazy(() => import('./BreadCrumbHeader'));

// Initialize context
const formContext = createContext({});
formContext.displayName = 'orderDetail';
export const useFormContext = () => {
  return useContext(formContext);
};

/**
 * SMS Delivery
 *
 * @return {*}
 */
const SMSDelivery = () => {
  // Get multiple language
  const { t }: any = useDictionary({ programId: '2081.F' });
  // Initialize state
  const [smsInfo, setSmsInfo] = useState<any>(null);
  const [lastSmsStored, setLastSmsStored] = useState<any>(null);

  // Api get sms info
  const [{ data: dataSmsInfo, loading }, refetchSmsInfo] = useAxios(
    {
      url: '/core/send-sms/sms-info',
      method: 'GET',
      params: { utcOffset: -Math.round(new Date().getTimezoneOffset() / 60) },
    },
    { manual: true, autoCancel: true },
  );

  // Api get last sms info
  const [{ data: smsStored, loading: loadingSmsStored }, refetchSmsStored] =
    useAxios(
      {
        url: '/core/send-sms/lastSMS-and-column-trans',
        method: 'POST',
      },
      { manual: true, autoCancel: true },
    );

  // Call api when component is Initialize
  useEffect(() => {
    refetchSmsInfo();
    refetchSmsStored();
  }, []);

  // Set data to state
  useEffect(() => {
    if (!dataSmsInfo?.data) return;
    setSmsInfo(dataSmsInfo?.data);
  }, [dataSmsInfo]);

  useEffect(() => {
    if (!smsStored?.data) return;
    setLastSmsStored(smsStored?.data?.messageStored);
  }, [smsStored]);

  // Values share with child component
  const value: any = {
    t,
    smsInfo,
    refetchSmsInfo,
    lastSmsStored,
  };

  return (
    <formContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading || loadingSmsStored} />
        <BreadCrumbHeader />
        <TabPanel defaultSelectedIndex={0} deferRendering={false}>
          <Item title={t('Store')}>
            <div style={{ padding: '10px 0px' }}>
              <ReportStore />
            </div>
          </Item>
          <Item title={t('Return Plan')}>
            <div style={{ padding: '10px 0px' }}>
              <ReturnPlan />
            </div>
          </Item>
          <Item title={t('Deposit Request')}>
            <div style={{ padding: '10px 0px' }}>
              <DepositRequest />
            </div>
          </Item>
          <Item title={t('Center Order')}>
            <div style={{ padding: '10px 0px' }}>
              <CenterOrder />
            </div>
          </Item>
        </TabPanel>
      </React.Suspense>
    </formContext.Provider>
  );
};

export default SMSDelivery;
