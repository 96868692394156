/**
 * Create date: 2024-06-20
 * Screen 2082.E
 */

import LoadPanel from 'app/components/LoadPanel';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import useDictionary from 'hooks/useDictionary';
import React, { useContext, createContext, useState, useRef } from 'react';
import FormSearch from './FormSearch';
import DetailTab from './DetailTab';
import SummaryTab from './SummaryTab';
import ArrayStore from 'devextreme/data/array_store';

// create context
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2082_EContext';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

function M2082_E() {
  const { t }: any = useDictionary({ programId: '2082.E' });
  const formSearchRef: any = useRef(null);
  const [detailData, setDetailData] = useState<any>([]);
  const [summaryData, setSummaryData] = useState<any>([]);

  const storeDetail = new ArrayStore({
    data: detailData,
    key: ['rowId', 'lineId', 'billType'],
  });

  const storeSummary = new ArrayStore({
    data: summaryData,
  });

  const value: any = {};
  /**
   * render
   */
  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <div style={{ marginBottom: 15, backgroundColor: '#fff' }}>
          <FormSearch
            t={t}
            setDetailData={setDetailData}
            setSummaryData={setSummaryData}
            ref={formSearchRef}
          />
        </div>
        <TabPanel
          className="custom-tabpanel-border-none bg-white"
          deferRendering={false}
          defaultSelectedIndex={0}
        >
          <Item title={t('Report On Specific Purchase And Sales (Detail)')}>
            <DetailTab
              t={t}
              store={storeDetail}
              formSearchRef={formSearchRef}
            />
          </Item>
          <Item title={t('Report On Specific Purchase And Sales (Summary)')}>
            <SummaryTab
              t={t}
              store={storeSummary}
              formSearchRef={formSearchRef}
            />
          </Item>
        </TabPanel>
      </React.Suspense>
    </ModuleContext.Provider>
  );
}

export default M2082_E;
