/**
 * Create date: 2023-06-12
 * Screen 2082.A
 */
import { DataGridFull } from 'app/components/DataGrid';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import useFormat from 'hooks/useFormat';
import moment from 'moment-timezone';
import { useRef } from 'react';
import { useModuleContext } from '..';

/**
 * Data Grid component
 *
 * @return {*}
 */
function TableContent() {
  const dataGridRef: any = useRef(null);
  const subGridRef: any = useRef(null);
  const context: any = useModuleContext();
  const { t, salesData, prdData, setTapName } = context;
  const { QtyFormat, AmountFormat, SingleFormat } = useFormat();

  // Render column of grid Vendor
  const columns: any = [
    {
      caption: t('Vendor'),
      alignment: 'center',
      columns: [
        {
          dataField: 'vendor_id',
          caption: t('vendor No.'),
          allowEditing: false,
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'vendor_nm',
          caption: t('Vendor'),
          allowEditing: false,
          showWhenGrouped: true,
        },
        // {
        //   dataField: 'producttype',
        //   caption: t('Product Type'),
        //   allowEditing: false,
        //   showWhenGrouped: true,
        // },
        {
          dataField: 'manager_nt',
          caption: t('Manager Note'),
          allowEditing: false,
          showWhenGrouped: true,
          visible: false,
        },
      ],
    },
    {
      caption: t('Summary'),
      alignment: 'center',
      columns: [
        {
          dataField: 'salesea',
          caption: t('Sales EA'),
          allowEditing: false,
          format: QtyFormat,
          dataType: 'number',
          showWhenGrouped: true,
          width: 100,
        },
        {
          dataField: 'salesamount',
          caption: t('Sales Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'salessupplyamount',
          caption: t('Sales Supply Amount'),
          format: AmountFormat,
          dataType: 'number',
          allowEditing: false,
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'salesvat',
          caption: t('Sales Vat'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'salescostamount',
          caption: t('Sales Cost Amount(VAT-)'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'salescostamountvat',
          caption: t('Sales Cost Amount(VAT+)'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'debit_amount',
          caption: t('Debit Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'profitamount',
          caption: t('Profit Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'profitamountvat',
          caption: t('Profit Amount (VAT)'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'profitrate',
          caption: t('Profit Rate'),
          allowEditing: false,
          format: SingleFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Forward'),
      alignment: 'center',
      columns: [
        {
          dataField: 'fdea',
          caption: t('Forward EA'),
          allowEditing: false,
          format: QtyFormat,
          dataType: 'number',
          showWhenGrouped: true,
          width: 100,
        },
        {
          dataField: 'fdamount',
          caption: t('Forward Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'fdsupplyamount',
          caption: t('Forward Supply Amount'),
          format: AmountFormat,
          dataType: 'number',
          allowEditing: false,
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'fdvat',
          caption: t('Forward Vat'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'fdcostamount',
          caption: t('Forward Cost Amount '),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Return'),
      alignment: 'center',
      columns: [
        {
          dataField: 'rdea',
          caption: t('Return EA'),
          allowEditing: false,
          format: QtyFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
          width: 100,
        },
        {
          dataField: 'rdamount',
          caption: t('Return Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
        },
        {
          dataField: 'rdsupplyamount',
          caption: t('Return Supply Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'rdvat',
          caption: t('Return Vat'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'rdcostamount',
          caption: t('Return Cost Amount '),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Payment'),
      alignment: 'center',
      columns: [
        {
          dataField: 'last_debit_amount',
          caption: t('Last Debit Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'payment_amount',
          caption: t('Payment Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'deduction_amount',
          caption: t('Deduction'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'deposit_amount',
          caption: t('Deposit'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Receive'),
      alignment: 'center',
      columns: [
        {
          dataField: 'receive_ea',
          caption: t('Receive EA'),
          allowEditing: false,
          format: QtyFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'receive_amount',
          caption: t('Receive Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'receive_supply',
          caption: t('Receive Supply'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'receive_vat',
          caption: t('VAT'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
  ];

  // Render column of grid product
  const subColumns: any = [
    {
      caption: t('Product'),
      alignment: 'center',
      columns: [
        {
          dataField: 'vendor_id',
          caption: t('vendor No.'),
          allowEditing: false,
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'vendor_nm',
          caption: t('Vendor'),
          allowEditing: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'productid',
          caption: t('Product ID'),
          allowEditing: false,
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'productcd',
          caption: t('Product Code'),
          allowEditing: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'productnm',
          caption: t('Product Name'),
          allowEditing: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'marketablesize',
          caption: t('Marketable Size'),
          allowEditing: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'producttp',
          caption: t('Product Type Id'),
          allowEditing: false,
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'producttpnm',
          caption: t('Product Type'),
          allowEditing: false,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Summary'),
      alignment: 'center',
      columns: [
        {
          dataField: 'salesea',
          caption: t('Sales EA'),
          allowEditing: false,
          format: QtyFormat,
          dataType: 'number',
          showWhenGrouped: true,
          width: 100,
        },
        {
          dataField: 'salesamount',
          caption: t('Sales Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'salessupply',
          caption: t('Sales Supply Amount'),
          format: AmountFormat,
          dataType: 'number',
          allowEditing: false,
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'salesvat',
          caption: t('Sales Vat'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'salescost',
          caption: t('Sales Cost Amount(VAT-)'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'salescostvat',
          caption: t('Sales Cost Amount(VAT+)'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'profitamount',
          caption: t('Profit Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'profitamountvat',
          caption: t('Profit Amount (VAT)'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'profitrate',
          caption: t('Profit Rate'),
          allowEditing: false,
          format: SingleFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Forward'),
      alignment: 'center',
      columns: [
        {
          dataField: 'forwardea',
          caption: t('Forward EA'),
          allowEditing: false,
          format: QtyFormat,
          dataType: 'number',
          showWhenGrouped: true,
          width: 100,
        },
        {
          dataField: 'forwardamount',
          caption: t('Forward Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'forwardsupply',
          caption: t('Forward Supply Amount'),
          format: AmountFormat,
          dataType: 'number',
          allowEditing: false,
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'forwardvat',
          caption: t('Forward Vat'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'forwardcost',
          caption: t('Forward Cost Amount '),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Return'),
      alignment: 'center',
      columns: [
        {
          dataField: 'returnea',
          caption: t('Return EA'),
          allowEditing: false,
          format: QtyFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
          width: 100,
        },
        {
          dataField: 'returnamount',
          caption: t('Return Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
        },
        {
          dataField: 'returnsupply',
          caption: t('Return Supply Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'returnvat',
          caption: t('Return Vat'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'returncost',
          caption: t('Return Cost Amount '),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
        },
      ],
    },
  ];

  /**
   * Export excel store grid
   *
   */
  const exportExcelStore = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  /**
   * Export excel product grid
   *
   */
  const exportExcelProduct = () => {
    subGridRef?.current?.instance?.exportToExcel();
  };

  return (
    <>
      <TabPanel
        defaultSelectedIndex={0}
        onSelectionChanged={(e: any) => {
          setTapName(t(e?.addedItems[0].title));
        }}
        className="theme-premiun-dx-multiview-wrapper-0"
      >
        <TabItem title={t('Vendor')}>
          <DataGridFull
            storageKeyInquiry="vendor"
            columns={columns}
            ref={dataGridRef}
            dataSource={salesData}
            options={{
              groupPanel: {
                visible: true,
              },
              columnAutoWidth: true,
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift(
                  {
                    location: 'before',
                    template: 'totalCount',
                  },
                  {
                    location: 'before',
                    widget: 'dxButton',
                    options: {
                      icon: 'xlsxfile',
                      text: t('Export Excel'),
                      disabled: '',
                      onClick: exportExcelStore,
                    },
                  },
                );
              },
              export: {
                fileName: `SMDC_2082.A_Sales Summary by Vendor_${moment().format(
                  'YYYYMMDD',
                )}`,
              },
              summary: {
                totalItems: [
                  {
                    column: 'fdea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'fdamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'fdsupplyamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'fdvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'fdcostamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salesea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salesamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salessupplyamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salesvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salescostamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salescostamountvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'debit_amount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'profitamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'profitamountvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'rdea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'rdamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'rdsupplyamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'rdvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'rdcostamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'last_debit_amount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'payment_amount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'deduction_amount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'deposit_amount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'receive_ea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'receive_amount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'receive_supply',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'receive_vat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                ],
                groupItems: [
                  {
                    summaryType: 'count',
                    displayFormat: '{0}',
                  },
                  {
                    column: 'fdea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                  },
                  {
                    column: 'fdamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'fdsupplyamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'fdvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'fdcostamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salesea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salesamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salessupplyamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salesvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salescostamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salescostamountvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'debit_amount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'profitamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'profitamountvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'rdea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'rdamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'rdsupplyamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'rdvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'rdcostamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },

                  {
                    column: 'last_debit_amount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                  },
                  {
                    column: 'payment_amount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                  },
                  {
                    column: 'deduction_amount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                  },
                  {
                    column: 'deposit_amount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                  },
                  {
                    column: 'receive_ea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                  },
                  {
                    column: 'receive_amount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                  },
                  {
                    column: 'receive_supply',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                  },
                  {
                    column: 'receive_vat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                  },
                ],
              },
            }}
          />
        </TabItem>
        <TabItem title={t('Product')}>
          <DataGridFull
            storageKeyInquiry="product"
            columns={subColumns}
            ref={subGridRef}
            dataSource={prdData}
            options={{
              key: '',
              groupPanel: {
                visible: true,
              },
              columnAutoWidth: true,
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift(
                  {
                    location: 'before',
                    template: 'totalCount',
                  },
                  {
                    location: 'before',
                    widget: 'dxButton',
                    options: {
                      icon: 'xlsxfile',
                      text: t('Export Excel'),
                      disabled: '',
                      onClick: exportExcelProduct,
                    },
                  },
                );
              },
              export: {
                fileName: `SMDC_2082.A_Sales Summary by product_${moment().format(
                  'YYYYMMDD',
                )}`,
              },
              summary: {
                totalItems: [
                  {
                    column: 'forwardea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'forwardamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'forwardsupply',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'forwardvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'forwardcost',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salesea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salesamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salessupply',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salesvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salescost',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salescostvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'profitamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'profitamountvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'returnea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'returnamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'returnsupply',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'returnvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'returncost',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                ],
                groupItems: [
                  {
                    summaryType: 'count',
                    displayFormat: '{0}',
                  },
                  {
                    column: 'forwardea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                  },
                  {
                    column: 'forwardamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'forwardsupply',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'forwardvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'forwardcost',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salesea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salesamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salessupply',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salesvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salescost',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salescostvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'profitamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'profitamountvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'returnea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'returnamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'returnsupply',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'returnvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'returncost',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                ],
              },
            }}
          />
        </TabItem>
      </TabPanel>
    </>
  );
}

export default TableContent;
