import { createSlice } from '@reduxjs/toolkit';

export interface menuState {
  mainMenu: Array<object>;
  mainMenuActive: number;
  subMenuActive: number;
  programActive: number;
  zoneClick: number;
}
const initialState: menuState = {
  mainMenu: [],
  mainMenuActive: 0,
  subMenuActive: 0,
  programActive: 0,
  zoneClick: 0,
};

const mainMenuSlice = createSlice({
  name: 'mainMenu',
  initialState,
  reducers: {
    fetchMenuData(state, action) {},
    setMenuData(state, action) {
      state.mainMenu = action.payload;
    },
    changeMainMenuActive(state, action) {
      state.mainMenuActive = action.payload;
    },
    changeSubMenuActive(state, action) {
      state.subMenuActive = action.payload;
    },
    changeProgramActive(state, action) {
      state.programActive = action.payload;
    },
    changZone(state, action) {
      state.zoneClick = action.payload;
    },
    clearMenuData(state) {
      state.mainMenu = [];
    },
  },
});
//Action
export const mainMenuAction = mainMenuSlice.actions;

//Selectors
export const selectMainMenu = (state: any) => state.mainMenu.mainMenu;
export const selectMainMenuActive = (state: any) =>
  state.mainMenu.mainMenuActive;
export const selectSubMenuActive = (state: any) => state.mainMenu.subMenuActive;
export const selectProgramActive = (state: any) => state.mainMenu.programActive;
export const selectZoneClick = (state: any) => state.mainMenu.zoneClick;

const mainMenuReducer = mainMenuSlice.reducer;

export default mainMenuReducer;
