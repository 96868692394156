/**
 * Update date: 12-05-2023
 * Screen 1043.7
 */

import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import React, { useState, useRef, useEffect } from 'react';
import useDictionary from 'hooks/useDictionary';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { Button, Form, Template } from 'devextreme-react';
import { notification } from 'utils/notification';
import { isMobile } from 'react-device-detect';
import InputFileImage from 'app/components/InputFileImage/InputFileImage';
import HtmlEditorTemplate from 'app/components/Templates/HtmlEditorTemplate';
import './styles.scss';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import useFormat from 'hooks/useFormat';
import { useApp } from 'app';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';

const defaultSearchParams = {
  logo_url: '',
  // logoFile: null,
  bottom_info: '',
  shipping_info: '',
  cancel_return: '',
  term_service: '',
  privacy_policy: '',
  standard_order_amount_discount: 0,
  min_order_amount: 0,
  credit_surcharge_rate: 0,
  debit_surcharge_rate: 0,
};

const M1043_7 = () => {
  const { themePro }: any = useApp();
  const { t }: any = useDictionary({});
  const { AmountFormat, PercentFormat } = useFormat();
  const tab1Ref: any = useRef(null);
  const tab2Ref: any = useRef(null);
  const tab3Ref: any = useRef(null);
  const [formData, setFormData] = useState<any>({ ...defaultSearchParams });
  const [formDataStored, setFormDataStored] = useState<any>({
    ...defaultSearchParams,
  });
  const [showPopupConfirmSave, setShowPopupConfirmSave] = useState(false);
  const [showPopupConfirmCancel, setShowPopupConfirmCancel] = useState(false);

  const [{ loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const [{ data: resData, loading: loadingGetData }] = useAxios(
    {
      url: `product/m1043_7/${'AOS'}`,
      method: 'GET',
    },
    { manual: false, autoCancel: true, useCache: false },
  );

  /**
   * fetch data
   */
  useEffect(() => {
    if (!resData?.data) return;
    setFormData(resData?.data);
    setFormDataStored({ ...resData?.data });
  }, [resData]);

  const onSave = async () => {
    const dataRequest = {
      ...formData,
      mall_cd: formData?.mall_cd || 'AOS',
    };
    const res = await refetchData({
      url: 'product/m1043_7',
      method: 'PUT',
      data: dataRequest,
    });
    notification({ res });
    if (res?.data?.status === '201') {
      setFormDataStored({ ...dataRequest });
    }
    setShowPopupConfirmSave(false);
  };
  /**
   * on cancel
   */
  const onCancel = () => {
    setFormData({ ...formDataStored });
    setShowPopupConfirmCancel(false);
  };
  /**
   * on select file
   * @param {*} file
   * @return {*}
   */
  const onSelectedFile = async file => {
    if (!file) {
      setFormData({ ...formData, logo_url: '' });
      return;
    }
    const formRequest = new FormData();
    formRequest.append('file', file);
    const res: any = await refetchData({
      url: '/user/upload/image',
      method: 'POST',
      data: formRequest,
    });
    setFormData({ ...formData, logo_url: res?.data?.data || '' });

    // const urlChange = file ? URL.createObjectURL(file) : '';
    // setFormData({ ...formData, logoFile: file, logo_url: urlChange });
  };
  /**
   * on change field
   * @param {*} ref
   * @param {*} fieldName
   * @param {*} value
   * @return {*}
   */
  const onChangeCustomeField = (ref, fieldName, value) => {
    if (!fieldName) return;
    ref?.current?.instance?.updateData(fieldName, value);
  };
  /**
   * render
   */
  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <LoadPanel visible={loadingfetchData || loadingGetData} />
      {themePro ? (
        <BreadCrumbPremium
          onSubmit={() => setShowPopupConfirmSave(true)}
          // onCancel={() => setShowPopupConfirmCancel(true)}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: 15,
            gap: 15,
            position: isMobile ? 'unset' : 'absolute',
            top: 60,
            right: 25,
            zIndex: 1,
          }}
        >
          <Button
            icon={'close'}
            text={t('Cancel')}
            onClick={() => setShowPopupConfirmCancel(true)}
          />
          <Button
            icon={'save'}
            text={t('Save')}
            onClick={() => setShowPopupConfirmSave(true)}
          />
        </div>
      )}
      <TabPanel defaultSelectedIndex={0} className="bg-white">
        <Item title={t('AOS Option')}>
          <Form
            ref={tab1Ref}
            showColonAfterLabel={false}
            labelLocation="top"
            colCount={2}
            formData={formData}
            items={[
              {
                caption: t('Logo'),
                itemType: 'group',
                colCount: 2,
                items: [
                  {
                    label: {
                      text: t('Logo Image'),
                    },
                    itemType: 'simple',
                    template: 'InputLogoImage',
                  },
                ],
              },
              {
                caption: t('Option'),
                itemType: 'group',
                colCount: isMobile ? 1 : 2,
                items: [
                  {
                    label: { text: t('Standard Order Amount Discount') },
                    dataField: 'standard_order_amount_discount',
                    editorType: 'dxNumberBox',
                    editorOptions: {
                      format: AmountFormat,
                      step: 0,
                      min: 0,
                    },
                  },
                  {
                    label: { text: t('Min Order Amount') },
                    dataField: 'min_order_amount',
                    editorType: 'dxNumberBox',
                    editorOptions: {
                      format: AmountFormat,
                      step: 0,
                      min: 0,
                    },
                  },
                  {
                    label: { text: t('Credit Card Surcharge (%)') },
                    dataField: 'credit_surcharge_rate',
                    editorType: 'dxNumberBox',
                    editorOptions: {
                      format: PercentFormat,
                      step: 0,
                      min: 0,
                    },
                  },
                  {
                    label: { text: t('Debit Card Surcharge (%)') },
                    dataField: 'debit_surcharge_rate',
                    editorType: 'dxNumberBox',
                    editorOptions: {
                      format: PercentFormat,
                      step: 0,
                      min: 0,
                    },
                  },
                ],
              },
              // { itemType: 'empty', colSpan: 2 },
            ]}
          >
            <Template
              name={`InputLogoImage`}
              component={() => (
                <InputFileImage
                  value={formData?.logo_url}
                  onChangeValue={data => onSelectedFile(data)}
                  id={'InputLogoImage'}
                  isPrevew={true}
                  minWidth={250}
                  minHeight={250}
                  isShowOrigin={true}
                  t={t}
                />
              )}
            />
          </Form>
        </Item>
        <Item title={t('Display Content')}>
          <div style={themePro ? {} : { padding: '15px 10px' }}>
            <Form
              ref={tab1Ref}
              showColonAfterLabel={false}
              labelLocation="top"
              colCount={3}
              formData={formData}
              items={[
                {
                  label: { text: ' ' },
                  dataField: 'bottom_info',
                  template: 'HtmlEditorTemplate',
                  colSpan: 3,
                  editorOptions: {
                    onChangeValue: e =>
                      onChangeCustomeField(tab1Ref, 'bottom_info', e),
                    title: t('AOS Bottom Text'),
                  },
                },
                {
                  label: { text: ' ' },
                  dataField: 'shipping_info',
                  template: 'HtmlEditorTemplate',
                  colSpan: 3,
                  editorOptions: {
                    onChangeValue: e =>
                      onChangeCustomeField(tab1Ref, 'shipping_info', e),
                    title: t('Shipping Infomation'),
                  },
                },
                {
                  label: { text: ' ' },
                  dataField: 'cancel_return',
                  template: 'HtmlEditorTemplate',
                  colSpan: 3,
                  editorOptions: {
                    onChangeValue: e =>
                      onChangeCustomeField(tab1Ref, 'cancel_return', e),
                    title: t('Cancel And Return'),
                  },
                },
              ]}
            >
              <Template
                name={`HtmlEditorTemplate`}
                component={HtmlEditorTemplate}
              />
            </Form>
          </div>
        </Item>
        <Item title={t('Terms Of Service')}>
          <div style={themePro ? {} : { padding: '15px 10px' }}>
            <Form
              ref={tab2Ref}
              showColonAfterLabel={false}
              labelLocation="top"
              colCount={3}
              formData={formData}
              items={[
                {
                  label: {
                    text: ' ',
                  },
                  dataField: 'term_service',
                  template: 'HtmlEditorTemplate',
                  colSpan: 3,
                  editorOptions: {
                    onChangeValue: e =>
                      onChangeCustomeField(tab2Ref, 'term_service', e),
                    title: t('Terms of Service'),
                    height: '60vh',
                  },
                },
              ]}
            >
              <Template
                name={`HtmlEditorTemplate`}
                component={HtmlEditorTemplate}
              />
            </Form>
          </div>
        </Item>
        <Item title={t('Privacy Policy')}>
          <div style={themePro ? {} : { padding: '15px 10px' }}>
            <Form
              ref={tab3Ref}
              showColonAfterLabel={false}
              labelLocation="top"
              colCount={3}
              formData={formData}
              items={[
                {
                  label: {
                    text: ' ',
                  },
                  dataField: 'privacy_policy',
                  template: 'HtmlEditorTemplate',
                  colSpan: 3,
                  editorOptions: {
                    onChangeValue: e =>
                      onChangeCustomeField(tab3Ref, 'privacy_policy', e),
                    title: t('Privacy Policy'),
                    height: '60vh',
                  },
                },
              ]}
            >
              <Template
                name={`HtmlEditorTemplate`}
                component={HtmlEditorTemplate}
              />
            </Form>
          </div>
        </Item>
      </TabPanel>
      <PopupConfirm
        visible={showPopupConfirmSave}
        content={t('Do you want to save?')}
        onOk={onSave}
        onHiding={() => setShowPopupConfirmSave(false)}
      />
      <PopupConfirm
        visible={showPopupConfirmCancel}
        content={t('Do you want to cancel all changed?')}
        onOk={onCancel}
        onHiding={() => setShowPopupConfirmCancel(false)}
      />
    </React.Suspense>
  );
};

export default M1043_7;
