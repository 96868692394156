import React, { useState } from 'react';
import PopupContent from './PopupContent';
interface IItemView {
  data: any;
  cursor?: boolean;
}

export const ItemView = ({ data, cursor }: IItemView) => {
  const [showPopup, setShowPopup] = useState<any>({ visible: false });

  return (
    <div
      className={`item-notification ${cursor && 'cursor-pointer'}`}
      about={data?.status === '0' ? 'error' : ''}
      onClick={() => {
        if (!cursor) return;
        setShowPopup({ visible: true });
      }}
    >
      <div>
        <img alt="avatar" src={data?.image} />
      </div>
      <div>
        <div className="item-notification-name">
          <span>{data?.name}</span>{' '}
          <span className="item-notification-time">{data?.time}</span>
        </div>
        <div className="item-notification-name">{data?.title}</div>
        <div className="item-notification-content">
          <span>{data?.content}</span>
        </div>
      </div>
      {showPopup?.visible && (
        <PopupContent onClose={() => setShowPopup({ visible: false })} />
      )}
    </div>
  );
};
