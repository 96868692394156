/**
 * Update date: 23-05-2023
 * Screen 2024.3
 */
import LoadPanel from 'app/components/LoadPanel';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import { useProductType } from 'hooks/useProductType';
import React, { createContext, useContext, useState } from 'react';
import DeliverDetail from './DetailTab';
import FormSearch from './FormSearch';
import DeliverTotal from './TotalTab';
/**
 * create context
 */
const consignDel = createContext({});

consignDel.displayName = 'consignDel';
export const useConsignDelivery = () => {
  const context = useContext(consignDel);
  return context;
};

function ConsignedDeliveryDetailInquiry() {
  const { t }: any = useDictionary({ programId: '2024.3' });
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const [deliverData, setDeliverData] = useState([]);
  const [deliverTotal, setDeliverTotal] = useState([]);
  const [storeSearchForm, setStoreSearchForm] = useState<any>(null);
  const [isResearch, setIsResearch] = useState<any>(null);

  const storeDetail: any = new ArrayStore({
    data: deliverData,
  });

  const storeTotal: any = new ArrayStore({
    data: deliverTotal,
    key: 'ID',
  });

  const value: any = {};

  return (
    <>
      <consignDel.Provider value={value}>
        <LoadPanel visible={loadingProductTpPermission} />
        <React.Suspense fallback={<LoadPanel visible={true} />}>
          <div style={{ padding: 10, backgroundColor: '#fff' }}>
            <FormSearch
              t={t}
              productTpPermission={productTpPermission}
              isResearch={isResearch}
              setIsResearch={setIsResearch}
              storeSearchForm={storeSearchForm}
              setStoreSearchForm={setStoreSearchForm}
              setDeliverData={setDeliverData}
              setDeliverTotal={setDeliverTotal}
            />
          </div>
          <br />
          <TabPanel deferRendering={false} defaultSelectedIndex={0}>
            <Item title={t('Shipping Request Details (Product)')}>
              <DeliverDetail t={t} storeDetail={storeDetail} />
            </Item>
            <Item title={t('Shipping Request Total (by Vendor)')}>
              <DeliverTotal
                t={t}
                setIsResearch={setIsResearch}
                storeTotal={storeTotal}
                storeSearchForm={storeSearchForm}
              />
            </Item>
          </TabPanel>
        </React.Suspense>
      </consignDel.Provider>
    </>
  );
}

export default ConsignedDeliveryDetailInquiry;
