import { Button, Form, Popup } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useModuleContext } from '..';

interface IPopupContent {
  onClose: () => void;
}
type TypeInfor = {
  title: string;
  value: string;
  colorValue?: string;
  borderBottom?: boolean;
};

function PopupContent({ onClose }: IPopupContent) {
  const { t }: any = useModuleContext() || {};

  const informationItem = ({
    title,
    value,
    colorValue,
    borderBottom = true,
  }: TypeInfor) => {
    return (
      <div
        style={{
          display: 'flex',
          padding: 10,
          borderRadius: 6,
          alignItems: 'center',
          borderBottom: borderBottom ? '1px solid #E4E7EC ' : 'none',
        }}
      >
        <span style={{ width: 150, fontWeight: 600, color: '#475467' }}>
          {t(title)}
        </span>
        <span
          style={{ color: colorValue ? colorValue : '#475467', width: 470 }}
        >
          {value}
        </span>
      </div>
    );
  };

  const content = () => {
    return (
      <div>
        <div className="modal-popup-header">
          <span className="title-page">{t('Claims')}</span>
          <div>
            <Button
              stylingMode="contained"
              text={t('Close')}
              icon="close"
              onClick={onClose}
            />
          </div>
        </div>
        <div className="body-padding-white">
          {informationItem({
            title: 'Send From',
            value: 'Song Jong Ko',
          })}
          {informationItem({
            title: 'Time',
            value: '20 minutes ago',
          })}
          {informationItem({
            title: 'Title',
            value: 'Claims',
            colorValue: 'red',
          })}
          {informationItem({
            title: 'Request Content',
            value:
              'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            borderBottom: false,
          })}
        </div>
      </div>
    );
  };

  return (
    <Popup
      className="modal-content-popup popup-order-custom-claim"
      visible={true}
      //   onHiding={onHiding}
      contentRender={content}
      height={'100vh'}
      width={700}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}

export default PopupContent;
