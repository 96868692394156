/**
 * Update date: 31-05-2023
 * Screen 2051.9
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import { NotFoundPage } from 'app/components/NotFoundPage';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import useLocalStorage from 'hooks/useLocalStorage';

const tabTitle = {
  tab0: 'Container',
  tab1: 'Product',
};
const FormSearch = React.lazy(() => import('./FormSearch'));
const ListContainerTab = React.lazy(() => import('./ContainerTab/List'));
const ViewDetail = React.lazy(() => import('./ContainerTab/View'));
const ListProductTab = React.lazy(() => import('./ProductTab'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'InventoryCountInquiryByContainer';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2051_9 = () => {
  const [dataLocal, setDataLocal] = useLocalStorage('2051.9_dataList', {});
  const { t }: any = useDictionary({ programId: '2051.9' });
  const [modeView, setModeView] = useState({ type: 'list' });
  const [storedDateSearch, setStoredDateSearch] = useState<any>(null);
  const [params, setParams] = useState({
    startDuration: new Date(),
    endDuration: new Date(),
    endProductDate: new Date(),
    use: '1',
    tabSelect: 0,
    dataList: {
      container: [],
      containerType: [],
      containerKind: [],
      zone: [],
    },
    container: '',
    containerType: '',
    containerKind: '',
    zone: '',
  });

  const [
    { data: dataContainer, loading: loadingContainer },
    refetchContainerTab,
  ] = useAxios(
    {
      url: '/warehouse/inv-count-container',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const [{ data: dataProduct, loading: loadingProduct }, refetchProductTab] =
    useAxios(
      {
        url: '/warehouse/inv-count-container/product',
        method: 'POST',
      },
      { manual: true, autoCancel: true, useCache: false },
    );
  /**
   * fetch data
   */
  useEffect(() => {
    if (!dataLocal) return;
    setParams(prev => ({
      ...prev,
      dataList: {
        ...prev.dataList,
        ...dataLocal,
      },
    }));
  }, [dataLocal]);

  const storeContainer: any = new ArrayStore({
    data: dataContainer?.data?.map((o, i) => ({
      ...o,
      id: i + 1,
    })),
    key: 'id',
  });

  const storeProduct: any = new ArrayStore({
    data: dataProduct?.data?.map((o, i) => ({
      ...o,
      classify: '0',
      id: i + 1,
    })),
    key: 'id',
  });

  /**
   * on submit search
   *
   * @param {*} data
   */
  const onSubmitSearch = data => {
    if (!params?.tabSelect) {
      refetchContainerTab({ data });
    } else {
      refetchProductTab({ data });
    }
  };

  /**
   * on change tab
   *
   * @param {*} title
   */
  const onChangeTabPanel = (title: any) => {
    if (title === t(tabTitle.tab0)) {
      setParams({ ...params, tabSelect: 0 });
    } else {
      setParams({ ...params, tabSelect: 1 });
    }
  };
  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return (
          <>
            <FormSearch />
            <TabPanel
              defaultSelectedIndex={0}
              onSelectionChanged={(e: any) => {
                onChangeTabPanel(t(e?.addedItems[0].title));
              }}
              className="body-padding-white"
            >
              <TabItem title={t(tabTitle.tab0)}>
                <ListContainerTab />
              </TabItem>
              <TabItem title={t(tabTitle.tab1)}>
                <ListProductTab />
              </TabItem>
            </TabPanel>
          </>
        );
      case 'view':
        return <ViewDetail />;
      default:
        return <NotFoundPage />;
    }
  };

  const value: any = {
    t,
    storeContainer,
    storeProduct,
    setModeView,
    modeView,
    params,
    setParams,
    storedDateSearch,
    setStoredDateSearch,
    onSubmitSearch,
    setDataLocal,
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingContainer || loadingProduct} />
        <BreadCrumbPremium />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2051_9;
