/**
 * Update date: 24-05-2023
 * Screen 2041.6
 */
import { BreadCrumbPremium } from 'app/components/BreadCrumbPremium';
import LoadPanel from 'app/components/LoadPanel';
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import { ROW_STATUS } from 'constant';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import useDictionary from 'hooks/useDictionary';
import useLocalStorage from 'hooks/useLocalStorage';
import moment from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { notification } from 'utils/notification';

const FormSearch = React.lazy(() => import('./FormSearch'));
const Tab1 = React.lazy(() => import('./Tab1'));
const Tab2 = React.lazy(() => import('./Tab2'));
const Tab2Edit = React.lazy(() => import('./Tab2Edit'));
/**
 * create context
 */
const ModuleContext = createContext({});
ModuleContext.displayName = 'ModificationReceivingContext';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

export default function ModificationReceiving() {
  const [dataLocal, setDataLocal] = useLocalStorage('2041.6_dataList', {});
  const { t }: any = useDictionary({ programId: '2041.6' });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataTab1, setDataTab1] = useState<any>([]);
  const [dataTab2, setDataTab2] = useState<any>([]);
  const [modeView, setModeView] = useState<any>({
    type: 'list',
    receiveId: null,
  });
  const [formData, setFormData] = useState<any>({
    fromDate: new Date(),
    toDate: new Date(),
    dataList: {
      productIds: [],
      vendorIds: [],
      vendorType: [],
    },
    productIds: '',
    vendorIds: '',
    vendorType: '',
  });
  // const [storedDataSearch, setStoredDataSearch] = useState<any>(null);
  /**
   * fetch data
   */
  useEffect(() => {
    if (!dataLocal) return;
    setFormData(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        ...dataLocal,
      },
    }));
  }, [dataLocal]);
  // Get data modification receiving
  const [{ loading }, refetchData] = useAxios(
    { method: 'POST' },
    { manual: true, autoCancel: true },
  );

  /**
   * on search submit
   *
   * @param {*} params
   */
  const onSearchSubmit = async forIndex => {
    const data = {
      ...formData,
      fromDate: formData?.fromDate
        ? moment(formData?.fromDate).format('YYYY-MM-DD')
        : null,
      toDate: formData?.toDate
        ? moment(formData?.toDate).format('YYYY-MM-DD')
        : null,
      productIds: formData?.dataList?.productIds.map(
        (map: any) => map?.productId,
      ),
      vendorIds: formData?.dataList?.vendorIds.map((o: any) => o?.vendorId),
      vendorTypes: formData?.dataList?.vendorType?.map(o => o?.code),
      taskType: 0,
      is20416: true,
    };
    // if (!params && storedDataSearch) {
    //   params = storedDataSearch;
    // }
    const index = forIndex !== undefined ? forIndex : selectedIndex;
    const res: any = await refetchData({
      url:
        index === 0
          ? 'warehouse/receive/by-line'
          : 'warehouse/receive/get-modify-date-data',
      data: data,
    });

    if (res?.data?.status === '200') {
      // setStoredDataSearch({ ...params });
      const dataMapping = res?.data?.data?.map(o => ({
        ...o,
        status: ROW_STATUS.NORMAL,
      }));
      if (index === 0) {
        setDataTab1(dataMapping);
      } else {
        setDataTab2(dataMapping);
      }
    } else {
      notification({ res });
    }
  };

  /**
   * on select index change
   *
   * @param {*} e
   */
  const onSelectedIndexChange = e => {
    setSelectedIndex(e);
  };

  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return (
          <>
            <BreadCrumbPremium />
            <FormSearch />
            <TabPanel
              deferRendering={false}
              repaintChangesOnly
              showNavButtons
              defaultSelectedIndex={0}
              selectedIndex={selectedIndex}
              onSelectedIndexChange={onSelectedIndexChange}
              className="bg-white"
            >
              <Item title={t('Receive Detail')}>
                <Tab1 dataTab1={dataTab1} />
              </Item>
              <Item title={t('Receive Bill')}>
                <Tab2 dataTab2={dataTab2} />
              </Item>
            </TabPanel>
          </>
        );
      case 'edit':
        return <Tab2Edit />;
      default:
        return <NotFoundPage />;
    }
  };

  const valueContext = {
    modeView,
    setModeView,
    formData,
    setFormData,
    setDataLocal,
    onSearchSubmit,
  };

  return (
    <ModuleContext.Provider value={valueContext}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading} />
        {renderContent()}
      </React.Suspense>
    </ModuleContext.Provider>
  );
}
