import { Button, Popup } from 'devextreme-react';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import useDictionary from 'hooks/useDictionary';
import React, {
  createContext,
  useContext,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import ProductAnalysis from './ProductAnalysis';
import LoadPanel from 'app/components/LoadPanel';
import { isMobile } from 'react-device-detect';

const ProductDetailContext = createContext({
  myPageApp: '',
});

ProductDetailContext.displayName = 'ProductDetailContext';

export const useProductDetail = () => {
  const context = useContext(ProductDetailContext);
  return context;
};

const PopupProductOrder = (props, ref) => {
  const [productData, setProductData] = useState<any>({});
  const [productId, setProductId] = useState<any>(null);
  const [visible, setVisible] = useState(false);
  const { t } = useDictionary({});

  useImperativeHandle(ref, () => ({
    onShowPopup,
  }));

  const onShowPopup = productId => {
    setVisible(true);
    setProductId(productId);
  };
  const onClosePopup = () => {
    setVisible(false);
    setProductId(null);
  };

  const content = () => {
    return (
      <div className="popup-product-detail">
        <div
          className="modal-popup-header"
          style={{
            padding: '5px 20px',
            borderBottom: '1px dotted #ccc',
          }}
        >
          <span
            className="title-page"
            style={{
              margin: 0,
              fontSize: 18,
              lineHeight: '28px',
              maxWidth: '30%',
              width: 'auto',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {productData?.product_nm || t('Product Name')}
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              maxWidth: '70%',
              width: 'auto',
            }}
          >
            <div
              style={{
                width: 'auto',
                padding: 8,
                backgroundColor: '#F2F4F7',
                borderRadius: 6,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                marginRight: 10,
              }}
            >
              <span style={{ marginRight: 50 }}>
                {t('*Reference date ( Today - 1 )')}
              </span>
              <span>
                {t(
                  '*Inventory = Inventory the previous day, Weekly = The forward quantity from inventory/the previous day~7 days, Monthly = Forward quantity from the previous day~30 days, Yearly = Forward quantity from the previous day~365',
                )}
              </span>
            </div>
            <div>
              <Button
                stylingMode="contained"
                text={t('Close')}
                icon="close"
                onClick={() => {
                  onClosePopup();
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ padding: '0 20px' }}>
          <TabPanel className="custom-tabpanel-border-none">
            <TabItem title={t('Product analysis')}>
              <ProductAnalysis />
            </TabItem>
          </TabPanel>
        </div>
      </div>
    );
  };

  const value: any = {
    productData,
    setProductData,
    productId,
    visible,
  };

  return (
    <ProductDetailContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <Popup
          className="modal-content-popupproduct"
          visible={visible}
          title={t('Product')}
          contentRender={content}
          animation={{ show: undefined, hide: undefined }}
          height={'95vh'}
          width={'75vw'}
          minWidth={'1100px'}
          position={{
            boundaryOffset: { x: undefined, y: 50 },
          }}
        />
      </React.Suspense>
    </ProductDetailContext.Provider>
  );
};

export default forwardRef(PopupProductOrder);
