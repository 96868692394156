/**
 * Update date: 05-06-2023
 * Screen 2054.6
 */
import React, { useState, useRef } from 'react';
import { DataGridFull } from 'app/components/DataGrid';
import { useInventoryContext } from '../index';
import moment from 'moment';
import useFormat from 'hooks/useFormat';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import ArrayStore from 'devextreme/data/array_store';
import { notification } from 'utils/notification';
import PopupConfirmDelete from 'app/components/PopupCommon/PopupConfirmDelete';
import useFormatTime from 'hooks/useFormatTime';
import PopupConfirmWithRef from 'app/components/PopupCommon/PopupConfirmWithRef/PopupConfirmWithRef';
import PopupConfirmDeleteWithRef from 'app/components/PopupCommon/PopupConfirmWithRef/PopupConfirmDeleteWithRef';

function InventoryTable() {
  const {
    locationData,
    zoneData,
    centerData,
    refetchDelete,
    refetchData,
    t,
    viewData,
    formRef,
  }: any = useInventoryContext();
  const viewRef = useRef<any>(null);
  const locationRef = useRef<any>(null);
  const zoneRef = useRef<any>(null);
  const centerRef = useRef<any>(null);
  const confirmSaveRef: any = useRef(null);
  const confirmDeleteRef: any = useRef(null);
  const { QtyFormat } = useFormat();
  const [isPopupDelete, setIsPopupDelete] = useState<boolean>(false);
  const { DateFormat } = useFormatTime();

  const storeView: any = new ArrayStore({
    data: viewData,
    key: ['stockTakingId', 'lineId'],
  });

  const storeLocation: any = new ArrayStore({
    data: locationData,
    key: ['stockTakingId', 'productId', 'optionId', 'zoneId', 'locationId'],
  });

  const storeZone: any = new ArrayStore({
    data: zoneData,
    key: ['zoneId', 'productId', 'optionId'],
  });

  const storeCenter: any = new ArrayStore({
    data: centerData,
    key: ['productId', 'optionId'],
  });

  const columnView: any = [
    {
      dataField: 'status',
      caption: t('Status'),
      width: 80,
      cellRender: (record: any) => {
        switch (record?.value) {
          case 'edit':
            return 'V';
          default:
            return null;
        }
      },
      showWhenGrouped: true,
      allowEditing: false,
    },
    {
      dataField: 'date',
      caption: t('Date'),
      dataType: 'date',
      format: DateFormat,
      allowEditing: false,
    },
    {
      dataField: 'programId',
      caption: t('Program ID'),
      allowEditing: false,
    },
    {
      dataField: 'stockTakingId',
      caption: t('Registration Number'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'zoneName',
      caption: t('Zone'),
      allowEditing: false,
    },
    {
      dataField: 'locationNm',
      caption: t('Location'),
      visible: true,
      alignment: 'left',
      allowEditing: false,
    },

    {
      dataField: 'productCode',
      caption: t('Product Code'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'productName',
      caption: t('Product Name'),
      allowEditing: false,
    },
    {
      dataField: 'vendorType',
      caption: t('Vendor Type'),
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'vendorName',
      caption: t('Vendor Name'),
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'marketableSize',
      caption: t('Marketable Size'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'optionName',
      caption: t('Option'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'quantityPerPack',
      caption: t('Quantity Per Pack'),
      allowEditing: false,
    },
    {
      dataField: 'stockTakingQt',
      caption: t('Quantity'),
      displayFormat: QtyFormat,
      allowEditing: true,
      dataType: 'number',
      validationRules: [{ type: 'required' }],
      cssClass: 'gridcell-editing',
    },
    {
      dataField: 'stocktakingEa',
      caption: t('Ea'),
      displayFormat: QtyFormat,
      allowEditing: false,
      calculateCellValue: record => {
        const { stockTakingQt = 0, quantityPerPack = 0 } = record || {};
        return stockTakingQt * quantityPerPack;
      },
    },
    {
      dataField: 'saveDate',
      caption: t('Save Date'),
      dataType: 'date',
      format: DateFormat,
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'registerUserName',
      caption: t('Registration User'),
      alignment: 'left',
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'modifyDate',
      caption: t('Modify Date'),
      dataType: 'date',
      format: DateFormat,
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'modifyUserName',
      caption: t('Modify'),
      alignment: 'left',
      visible: false,
      allowEditing: false,
    },
  ];
  const columnLocations: any = [
    {
      dataField: 'stockTakingId',
      caption: t('Stock Taking Id'),
      visible: false,
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'zoneId',
      caption: t('Zone Id'),
      visible: false,
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'zoneName',
      caption: t('Zone'),
      allowEditing: false,
    },
    {
      dataField: 'locationId',
      caption: t('Location Id'),
      visible: false,
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'locationNm',
      caption: t('Location'),
      visible: true,
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'productId',
      caption: t('Product Id'),
      alignment: 'left',
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'productCode',
      caption: t('Product Code'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'productName',
      caption: t('Product Name'),
      allowEditing: false,
    },
    {
      dataField: 'vendorType',
      caption: t('Vendor Type'),
      visible: false,
      allowEditing: false,
    },{
      dataField: 'vendorName',
      caption: t('Vendor Name'),
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'marketableSize',
      caption: t('Marketable Size'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'barCode',
      caption: t('Barcode'),
      visible: false,
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'optionId',
      caption: t('Option Id'),
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'optionCode',
      caption: t('Option Code'),
      alignment: 'left',
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'optionName',
      caption: t('Option'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'quantityPerPack',
      caption: t('Quantity Per Pack'),
      allowEditing: false,
    },
    {
      dataField: 'stockTakingQt',
      caption: t('Quantity'),
      displayFormat: QtyFormat,
      allowEditing: false,
    },
    {
      dataField: 'stocktakingEa',
      caption: t('Ea'),
      displayFormat: QtyFormat,
      allowEditing: false,
    },
  ];

  const columnsZone: any = [
    {
      dataField: 'zoneId',
      caption: t('Zone Id'),
      visible: false,
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'zoneName',
      caption: t('Zone'),
      allowEditing: false,
    },
    {
      dataField: 'productId',
      caption: t('Product Id'),
      alignment: 'left',
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'productCode',
      caption: t('Product Code'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'productName',
      caption: t('Product Name'),
      allowEditing: false,
    },
    {
      dataField: 'vendorType',
      caption: t('Vendor Type'),
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'vendorName',
      caption: t('Vendor Name'),
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'marketableSize',
      caption: t('Marketable Size'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'barCode',
      caption: t('Barcode'),
      visible: false,
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'optionId',
      caption: t('Option Id'),
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'optionCode',
      caption: t('Option Code'),
      alignment: 'left',
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'optionName',
      caption: t('Option'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'quantityPerPack',
      caption: t('Quantity Per Pack'),
      allowEditing: false,
    },
    {
      dataField: 'stockTakingQt',
      caption: t('Quantity'),
      displayFormat: QtyFormat,
      allowEditing: false,
    },
    {
      dataField: 'stocktakingEa',
      caption: t('Ea'),
      displayFormat: QtyFormat,
      allowEditing: false,
    },
  ];

  const columnsCenter: any = [
    {
      dataField: 'productId',
      caption: t('Product Id'),
      alignment: 'left',
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'productCode',
      caption: t('Product Code'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'productName',
      caption: t('Product Name'),
      allowEditing: false,
    },
    {
      dataField: 'vendorType',
      caption: t('Vendor Type'),
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'vendorName',
      caption: t('Vendor Name'),
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'marketableSize',
      caption: t('Marketable Size'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'barCode',
      caption: t('Barcode'),
      visible: false,
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'optionId',
      caption: t('Option Id'),
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'optionCode',
      caption: t('Option Code'),
      alignment: 'left',
      visible: false,
      allowEditing: false,
    },
    {
      dataField: 'optionName',
      caption: t('Option'),
      alignment: 'left',
      allowEditing: false,
    },
    {
      dataField: 'quantityPerPack',
      caption: t('Quantity Per Pack'),
      allowEditing: false,
    },
    {
      dataField: 'stockTakingQt',
      caption: t('Quantity'),
      displayFormat: QtyFormat,
      allowEditing: false,
    },
    {
      dataField: 'stocktakingEa',
      caption: t('Ea'),
      displayFormat: QtyFormat,
      allowEditing: false,
    },
  ];

  const countGroupColumns = {
    totalItems: [
      {
        column: 'stocktakingEa',
        summaryType: 'sum',
        valueFormat: QtyFormat,
        displayFormat: '{0}',
        dataType: 'number',
      },
      {
        column: 'stockTakingQt',
        summaryType: 'sum',
        valueFormat: QtyFormat,
        displayFormat: '{0}',
        dataType: 'number',
      },
    ],
    groupItems: [
      {
        summaryType: 'count',
        displayFormat: '{0}',
      },
      {
        column: 'stocktakingEa',
        summaryType: 'sum',
        alignByColumn: true,
        showInGroupFooter: false,
        valueFormat: QtyFormat,
        displayFormat: '{0}',
        dataType: 'number',
      },
      {
        column: 'stockTakingQt',
        summaryType: 'sum',
        alignByColumn: true,
        showInGroupFooter: false,
        valueFormat: QtyFormat,
        displayFormat: '{0}',
        dataType: 'number',
      },
    ],
  };

  const validateSaveDetail = () => {
    const dataSource: any =
      viewRef?.current?.instance?.getDataSource()?._store?._array || [];
    const dataChangeLength = dataSource?.filter(
      o => o?.status === 'edit',
    )?.length;
    if (!dataChangeLength) {
      return notification({
        type: 'error',
        message: t('You must be select at least one record. Please try again!'),
      });
    }
    const content = `${t('Do you want to save {0} items?')}`.replace(
      '{0}',
      dataChangeLength,
    );
    confirmSaveRef?.current?.onOpen?.(content);
  };

  const onSaveDetail = async () => {
    const dataSource: any =
      viewRef?.current?.instance?.getDataSource()?._store?._array || [];
    const dataChange = dataSource
      ?.filter(o => o?.status === 'edit')
      ?.map(o => ({
        stockTakingId: o?.stockTakingId,
        lineId: o?.lineId,
        quantity: o?.stockTakingQt || 0,
      }));

    const res = await refetchData({
      url: '/warehouse/inventory-warehouse/detail-update-qty',
      method: 'POST',
      data: { data: dataChange },
    });
    notification({ res });
    if (res?.data?.status === '200') {
      formRef?.current?.onSearch?.();
    }
  };

  const validateDelete = () => {
    const selectedRowKeysLength =
      viewRef?.current?.instance?.getSelectedRowKeys?.()?.length;
    if (!selectedRowKeysLength) {
      return notification({
        type: 'error',
        message: t('You must be select at least one record. Please try again!'),
      });
    }
    confirmDeleteRef?.current?.onOpen?.(selectedRowKeysLength);
  };

  const onDeleteDetail = async () => {
    const selectedRowKeys: any =
      viewRef?.current?.instance?.getSelectedRowKeys();
    const res = await refetchData({
      url: '/warehouse/inventory-warehouse/detail-delete',
      method: 'POST',
      data: { data: selectedRowKeys },
    });
    notification({ res });
    if (res?.data?.status === '200') {
      formRef?.current?.onSearch?.();
    }
  };
  /**
   * on export excel
   *
   * @return {*}
   */
  const exportViewExcel = () => {
    if (viewRef?.current.instance?.getVisibleRows()?.length === 0) {
      return;
    }
    viewRef?.current?.instance?.exportToExcel();
  };

  /**
   * on export excel
   *
   * @return {*}
   */
  const exportLocationExcel = () => {
    if (locationRef?.current.instance?.getVisibleRows()?.length === 0) {
      return;
    }
    locationRef?.current?.instance?.exportToExcel();
  };
  /**
   * on export excel
   *
   * @return {*}
   */
  /**
   * on export excel
   *
   * @return {*}
   */
  const exportZoneExcel = () => {
    if (zoneRef?.current.instance?.getVisibleRows()?.length === 0) {
      return;
    }
    zoneRef?.current?.instance?.exportToExcel();
  };
  const exportCenterExcel = () => {
    if (centerRef?.current.instance?.getVisibleRows()?.length === 0) {
      return;
    }
    centerRef?.current?.instance?.exportToExcel();
  };

  const onHandleDelete = () => {
    const selectedKeys = locationRef?.current?.instance?.getSelectedRowKeys();
    if (selectedKeys?.length === 0)
      return notification({
        message: t('You must be select at least one record. Please try again!'),
        type: 'error',
      });
    setIsPopupDelete(true);
  };

  /**
   * on delete
   *
   */
  const onDelete = async () => {
    const selectedKeys = locationRef?.current?.instance?.getSelectedRowKeys();
    const res = await refetchDelete({
      data: selectedKeys,
    });
    notification({ res });
    if (res?.data?.status == '200') {
      formRef?.current?.onSearch?.();
    }
    setIsPopupDelete(!isPopupDelete);
  };

  const onRowUpdatingDetail = e => {
    if (e.newData) {
      storeView
        .update(e?.key, { status: 'edit' })
        .done(() => {})
        .fail(() => {});
    }
  };

  window['storeLocation'] = storeLocation;
  window['storeZone'] = storeZone;
  window['storeCenter'] = storeCenter;

  return (
    <>
      <TabPanel
        defaultSelectedIndex={0}
        className="theme-premiun-dx-multiview-wrapper-0"
      >
        <TabItem title={t('View Detail')}>
          <div>
            <DataGridFull
              ref={viewRef}
              dataSource={storeView}
              columns={columnView}
              fixedLeft={4}
              fixedRight={2}
              options={{
                columnAutoWidth: true,
                export: {
                  fileName:
                    'SMDC_2054.6 Inquiry Inventory Warehouse Detail_View_' +
                    moment().format('YYYYMMDD'),
                },
                groupPanel: { visible: true },
                editing: {
                  mode: 'cell',
                  allowUpdating: true,
                  selectTextOnEditStart: true,
                },
                selection: {
                  mode: 'multiple',
                  selectAllMode: 'allPages',
                  showCheckBoxesMode: 'onClick',
                },
                onRowUpdating: onRowUpdatingDetail,
                onToolbarPreparing: (e: any) => {
                  e.toolbarOptions.items.unshift(
                    {
                      location: 'before',
                      template: 'totalCount',
                    },
                    {
                      location: 'before',
                      widget: 'dxButton',
                      options: {
                        icon: 'xlsxfile',
                        text: t('Export Excel'),
                        onClick: () => exportViewExcel(),
                      },
                    },
                    {
                      location: 'after',
                      widget: 'dxButton',
                      options: {
                        icon: 'save',
                        text: t('Save'),
                        onClick: validateSaveDetail,
                      },
                    },
                    {
                      location: 'after',
                      widget: 'dxButton',
                      options: {
                        icon: 'trash',
                        text: t('Delete'),
                        onClick: validateDelete,
                      },
                    },
                  );
                },
                summary: countGroupColumns,
              }}
            />
          </div>
        </TabItem>
        <TabItem title={t('Location')}>
          <div>
            <DataGridFull
              ref={locationRef}
              dataSource={storeLocation}
              columns={columnLocations}
              options={{
                columnAutoWidth: true,
                key: [
                  'stockTakingId',
                  'productId',
                  'optionId',
                  'zoneId',
                  'locationId',
                ],
                export: {
                  fileName:
                    'SMDC_2054.6 Inquiry Inventory Warehouse Detail_Locaiton_' +
                    moment().format('YYYYMMDD'),
                },
                scrolling: {
                  columnRenderingMode: 'virtual',
                },
                onToolbarPreparing: (e: any) => {
                  e.toolbarOptions.items.unshift(
                    {
                      location: 'before',
                      template: 'totalCount',
                    },
                    {
                      location: 'before',
                      widget: 'dxButton',
                      options: {
                        icon: 'xlsxfile',
                        text: t('Export Excel'),
                        onClick: () => exportLocationExcel(),
                      },
                    },
                    {
                      location: 'after',
                      widget: 'dxButton',
                      options: {
                        icon: 'trash',
                        text: t('Delete'),
                        onClick: onHandleDelete,
                      },
                    },
                  );
                },
                groupPanel: {
                  visible: true,
                },
                selection: {
                  mode: 'multiple',
                  selectAllMode: 'allPages',
                  showCheckBoxesMode: 'onClick',
                },
                summary: countGroupColumns,
              }}
            />
          </div>
        </TabItem>
        <TabItem title={t('Zone')}>
          <div>
            <DataGridFull
              ref={zoneRef}
              dataSource={storeZone}
              columns={columnsZone}
              options={{
                columnAutoWidth: true,
                export: {
                  fileName:
                    'SMDC_2054.6 Inquiry Inventory Warehouse Detail_Zone_' +
                    moment().format('YYYYMMDD'),
                },
                scrolling: {
                  columnRenderingMode: 'virtual',
                },
                onToolbarPreparing: (e: any) => {
                  e.toolbarOptions.items.unshift(
                    {
                      location: 'before',
                      template: 'totalCount',
                    },
                    {
                      location: 'before',
                      widget: 'dxButton',
                      options: {
                        icon: 'xlsxfile',
                        text: t('Export Excel'),
                        onClick: () => exportZoneExcel(),
                      },
                    },
                  );
                },
                groupPanel: {
                  visible: true,
                },
                summary: countGroupColumns,
              }}
            />
          </div>
        </TabItem>
        <TabItem title={t('Center')}>
          <div>
            <DataGridFull
              ref={centerRef}
              dataSource={storeCenter}
              columns={columnsCenter}
              options={{
                columnAutoWidth: true,
                export: {
                  fileName:
                    'SMDC_2054.6 Inquiry Inventory Warehouse Detail_Center_' +
                    moment().format('YYYYMMDD'),
                },
                scrolling: {
                  columnRenderingMode: 'virtual',
                },
                onToolbarPreparing: (e: any) => {
                  e.toolbarOptions.items.unshift(
                    {
                      location: 'before',
                      template: 'totalCount',
                    },
                    {
                      location: 'before',
                      widget: 'dxButton',
                      options: {
                        icon: 'xlsxfile',
                        text: t('Export Excel'),
                        onClick: () => exportCenterExcel(),
                      },
                    },
                  );
                },
                groupPanel: {
                  visible: true,
                },
                summary: countGroupColumns,
              }}
            />
          </div>
        </TabItem>
      </TabPanel>
      <PopupConfirmDelete
        visible={isPopupDelete}
        content={`${t('Do you want to delete {0} items?')}`.replace(
          '{0}',
          locationRef?.current?.instance?.getSelectedRowKeys()?.length,
        )}
        onOk={onDelete}
        onHiding={() => setIsPopupDelete(false)}
      />
      <PopupConfirmWithRef ref={confirmSaveRef} onOk={onSaveDetail} />
      <PopupConfirmDeleteWithRef ref={confirmDeleteRef} onOk={onDeleteDetail} />
    </>
  );
}

export default InventoryTable;
