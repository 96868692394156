import { router } from 'constant/router';
import { Popover, ScrollView } from 'devextreme-react';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import { iconRing } from 'images';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useModuleContext } from '..';
import { ItemView } from '../ItemView';
import useDictionary from 'hooks/useDictionary';

export const NotificationPopover = () => {
  const { t }: any = useDictionary({});
  const history = useHistory();
  const [visible, setVisible] = useState(false);

  const dataTest = [
    {
      image:
        'https://s3-alpha-sig.figma.com/img/81da/3289/c5481934b8a3355aa4af9a3107310100?Expires=1665360000&Signature=NR8TgkUVdMZPRH3dIF1EHXix8qRD41O-tvv17LQYO67-FnMQJqHNMEw2sVJfjbAkFgHZlIMFqpIqKbl~Dcbtp7wBVdcZZoGrqQ5IFbzb7GoHsJ-7eZhSOFXqUClyIgVEQ~mDmEA7LSCdQmmrdoeyM1lOZXjHkdJgyBBHXapnfSzFlex02tXUk1mggk2wQeDpprqSzd6lQH2oE-cK3f-L-z39a-TJ220Fq6AFSo7UoM2lgwYaF2m0027jKU9vasK5O2G1UCfVnhHVoL15epi95f7mIKxjS0qbr28yrmuk-McYmkS4anx8tgtfhhpU9sT5OCeLk5~hHyy1pK8411YyTA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
      name: 'Marvin McKinney',
      time: '1 mins ago',
      title: 'Receiving registration without ordering',
      content:
        'Bill ID: SMDC-PH-220430-27664 Date: 2022-04-30 Total: 50.000.000',
      status: '1',
    },
    {
      image:
        'https://s3-alpha-sig.figma.com/img/81da/3289/c5481934b8a3355aa4af9a3107310100?Expires=1665360000&Signature=NR8TgkUVdMZPRH3dIF1EHXix8qRD41O-tvv17LQYO67-FnMQJqHNMEw2sVJfjbAkFgHZlIMFqpIqKbl~Dcbtp7wBVdcZZoGrqQ5IFbzb7GoHsJ-7eZhSOFXqUClyIgVEQ~mDmEA7LSCdQmmrdoeyM1lOZXjHkdJgyBBHXapnfSzFlex02tXUk1mggk2wQeDpprqSzd6lQH2oE-cK3f-L-z39a-TJ220Fq6AFSo7UoM2lgwYaF2m0027jKU9vasK5O2G1UCfVnhHVoL15epi95f7mIKxjS0qbr28yrmuk-McYmkS4anx8tgtfhhpU9sT5OCeLk5~hHyy1pK8411YyTA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
      name: 'Marvin McKinney',
      time: '1 mins ago',
      title: 'Receiving registration without ordering',
      content:
        'Bill ID: SMDC-PH-220430-27664 Date: 2022-04-30 Total: 50.000.000',
      status: '0',
    },
  ];

  const onHiding = () => {
    setVisible(false);
  };

  return (
    <div
      className="notification-alarm"
      style={{ position: 'relative' }}
      onClick={() => setVisible(true)}
    >
      <span
        style={{
          position: 'absolute',
          right: -5,
          top: -5,
          width: 16,
          height: 16,
          backgroundColor: 'red',
          color: 'white',
          borderRadius: 8,
          textAlign: 'center',
          fontSize: '12px',
        }}
      >
        {0}
      </span>
      <img height={20} width={20} src={iconRing} alt="notification" />
      <Popover
        className="popover-notification"
        target="#notification-alarm"
        position="top"
        width={500}
        visible={visible}
        onHiding={onHiding}
      >
        <div>
          <div style={{ padding: 10, borderBottom: '1px solid #E4E7EC' }}>
            <span
              style={{
                fontWeight: 600,
                fontSize: 18,
                lineHeight: '28px',
              }}
            >
              {t('Notification')}
            </span>
          </div>
        </div>

        <TabPanel
          // defaultSelectedIndex={tabPanelIndex}
          // selectedIndex={tabPanelIndex}
          // onSelectionChanged={(e: any) => {
          //   setTabPanelIndex(tabPanelIndex === 1 ? 0 : 1);
          // }}
          className="theme-premiun-dx-multiview-wrapper-0"
        >
          <TabItem title={`${t('All')} ({0})`}>
            <div style={{ padding: '20px' }}>
              <ScrollView>
                <div style={{ maxHeight: 600, height: '100%' }}>
                  {dataTest?.map((o, i) => (
                    <ItemView data={o} key={i} />
                  ))}
                </div>
              </ScrollView>
            </div>
          </TabItem>
          <TabItem title={`${t('Pending Status')} ({0})`}>
            <div style={{ padding: '20px' }}></div>
          </TabItem>
          <TabItem title={`${t('Error')} ({0})`}>
            <div style={{ padding: '20px' }}></div>
          </TabItem>
          <TabItem title={`${t('Claims')} ({4})`}>
            <div style={{ padding: '20px' }}></div>
          </TabItem>
        </TabPanel>
        <div
          className="cursor-pointer"
          style={{
            padding: 10,
            borderTop: '1px solid #f2f2f2',
            textAlign: 'center',
          }}
        >
          <span
            style={{
              fontWeight: 600,
              fontSize: 14,
              lineHeight: '20px',
              color: '#6941C6',
            }}
            onClick={() => {
              history.push(router.notification);
              setTimeout(() => {
                setVisible(false);
              }, 100);
            }}
          >
            {t('See all notifications')}
          </span>
        </div>
      </Popover>
    </div>
  );
};
