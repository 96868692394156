/**
 * Update date: 14-06-2023
 * Popup confirm component
 */
import React, { memo, forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Popup } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { fileDelete } from 'images';

interface IPopupConfirmDeleteWithRef {
  onOk?: () => void;
  onHiding?: () => void;
  content?: any;
}
const PopupConfirmDeleteWithRef = (
  { onOk, onHiding, content = '' }: IPopupConfirmDeleteWithRef,
  ref,
) => {
  const { t }: any = useDictionary({});
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [countRowDelete, setCountRowDelete] = useState(0);

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  const onOpen = (countRow = 0) => {
    setCountRowDelete(countRow || 0);
    setShowPopupConfirm(true);
  };

  const onClickYes = () => {
    if (showPopupConfirm) {
      onOk?.();
    }
    setShowPopupConfirm(false);
  };

  const onClickNo = () => {
    if (showPopupConfirm) {
      onHiding?.();
    }
    setShowPopupConfirm(false);
  };

  return (
    <Popup
      visible={showPopupConfirm}
      showTitle={true}
      title={''}
      width={350}
      height={'auto'}
      className="modal-confirm-popup"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          gap: 10,
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <img className="icon" src={fileDelete} alt="icon warning" />
        </div>
        <div className="modal-confirm-popup-des">
          {content
            ? content
            : countRowDelete
            ? `${t('Do you want to delete {0} items?')}`.replace(
                '{0}',
                `${countRowDelete}`,
              )
            : t(`Do you want to delete item?`)}
        </div>
        <br />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 20,
          }}
        >
          <Button text={t('Cancel')} onClick={onClickNo} width={'100%'} />
          <Button
            className="button-warning"
            text={t('Delete')}
            onClick={onClickYes}
            width={'100%'}
          />
        </div>
      </div>
    </Popup>
  );
};

export default memo(forwardRef(PopupConfirmDeleteWithRef));
