/**
 * Update date: 2024-03-01
 * Screen 2017.5
 */
import DateOptions from 'app/components/DateOptions';
import LoadPanel from 'app/components/LoadPanel';
import { Form } from 'devextreme-react';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import useDictionary from 'hooks/useDictionary';
import useFormatTime from 'hooks/useFormatTime';
import React, { createContext, useContext, useRef, useState } from 'react';

const InventoryTab = React.lazy(() => import('./InventoryTab'));
const ZoneMoveTab = React.lazy(() => import('./ZoneMoveTab'));

// Create context
const ModuleContext = createContext({});
ModuleContext.displayName = '2017.5Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2017_5 = () => {
  // create state
  const { DateFormat } = useFormatTime();
  const { t }: any = useDictionary({ programId: '2017.5' });
  const formRef: any = useRef(null);
  const [formSearchData, setFormSearchData] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
  });

  /**
   * on field data change
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'fromDate':
        if (
          formSearchData.endDate &&
          e?.value &&
          e?.value > formSearchData.endDate
        ) {
          formRef?.current?.instance.updateData('endDate', null);
        }
        break;
      case 'endDate':
        if (
          formSearchData.fromDate &&
          e?.value &&
          e?.value < formSearchData.fromDate
        )
          formRef?.current?.instance.updateData('fromDate', null);
        break;
      default:
        break;
    }
  };

  // Onchange date when choose date option
  const onChangeDate = (fromDateValue, endDateValue) => {
    formRef?.current?.instance?.updateData('fromDate', fromDateValue);
    formRef?.current?.instance?.updateData('endDate', endDateValue);
  };

  const value: any = {};

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <div
          className="bg-white"
          style={{ padding: '15px', borderRadius: '1px', marginBottom: '10px' }}
        >
          <DateOptions
            onChangeValue={(fromDate, endDate) =>
              onChangeDate(fromDate, endDate)
            }
          />
          <Form
            ref={formRef}
            showColonAfterLabel={false}
            labelLocation="top"
            colCount={2}
            formData={formSearchData}
            onFieldDataChanged={onFieldDataChanged}
            items={[
              {
                itemType: 'group',
                colCount: 2,
                items: [
                  {
                    label: { text: t('From Import Date') },
                    dataField: 'fromDate',
                    editorType: 'dxDateBox',
                    editorOptions: {
                      displayFormat: DateFormat,
                    },
                    isRequired: true,
                  },
                  {
                    label: { text: t('End Import Date') },
                    dataField: 'endDate',
                    editorType: 'dxDateBox',
                    editorOptions: {
                      displayFormat: DateFormat,
                    },
                    isRequired: true,
                  },
                ],
              },
            ]}
          />
        </div>
        <TabPanel className="bg-white" deferRendering={false}>
          <Item title={t('Inventory Inspection')}>
            <InventoryTab formMastRef={formRef} />
          </Item>
          <Item title={t('Zone Move')}>
            <ZoneMoveTab formMastRef={formRef} />
          </Item>
        </TabPanel>
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2017_5;
