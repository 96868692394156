export const VENDOR_STATUS = {
  0: 'Unconfirmed',
  1: 'Confirmed',
  2: 'Product in preparation',
  3: 'Delivery in preparation',
  4: 'In delivery',
  5: 'Delivery complete',
  9: 'Cancellation complete',
};

export const CONSIGNMENT_VENDOR_STATUS = [
  { value: '1', label: 'Consignment Operating' },
  { value: '2', label: 'Consignment Operation Expiration' },
];

export const TRADE_PAYABLE_VENDOR_TYPE = [
  { value: '0', label: '' },
  { value: '1', label: 'Receiving' },
  { value: '2', label: 'Carrying Out' },
  { value: '3', label: 'Carrying Out Container' },
  { value: '4', label: 'Payment' },
  { value: '5', label: 'Deposit' },
  { value: '6', label: 'Deduction' },
];

export const SEND_TASK_TYPE = [
  { value: '1', label: 'Unsent' },
  { value: '2', label: 'Sent' },
];

export const UPLOAD_EXCEL_PROGRAMCD = {
  '1047_1': '1047.1',
};

export const RESERVE_TYPE = [
  { value: '0', label: 'Wait To Transfer' },
  { value: '1', label: 'Transfer Complete' },
  { value: '9', label: 'Error' },
];

export const MALL_STATUS_TP = [
  { value: '0', label: 'Normal' },
  { value: '1', label: 'Out Of Stock' },
  { value: '2', label: 'Hidden' },
];

export const LIMIT_ROWS = 50000;
export const LIMIT_ROWS_MESS =
  'The search data is larger than {0} records. For the system to work properly, we have limited the processing to {0} records.';
