/**
 * Update date: 26-05-2023
 * Screen 2043.2
 */
import LoadPanel from 'app/components/LoadPanel';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import useDictionary from 'hooks/useDictionary';
import React from 'react';
import ProcessOrderUploadTab from './OrderUploadTab';
import MallIntegrationTab from './MallIntegrationTab';

const ProcessOrderUpload = () => {
  const { t }: any = useDictionary({ programId: '2043.2' });
  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <TabPanel
        className="custom-tabpanel-border-none"
        defaultSelectedIndex={0}
        deferRendering={false}
      >
        <Item title={t('Order Upload')}>
          <ProcessOrderUploadTab />
        </Item>
        <Item title={t('Mall Integration')}>
          <MallIntegrationTab />
        </Item>
      </TabPanel>
    </React.Suspense>
  );
};

export default ProcessOrderUpload;
