/**
 * Update date: 22-05-2023
 * Screen 2017.1
 */
import React, { useEffect, useState, useRef } from 'react';
import ArrayStore from 'devextreme/data/array_store';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import FormSearch from '../FormSearch/index';
import { DataGridFull } from 'app/components/DataGrid';
import useFormat from 'hooks/useFormat';
import useFormatTime from 'hooks/useFormatTime';
import { useMallProductMaster } from '..';
import { ROW_STATUS } from 'constant';
import { notification } from 'utils/notification';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import useDictionary from 'hooks/useDictionary';
import { useApp } from 'app';

function ListMall() {
  const { themePro }: any = useApp();
  const { t }: any = useDictionary({
    programId: '2017.1',
  });
  const mallProductMasterContext: any = useMallProductMaster();
  const { params, mallProductData, updateMallProduct, refetchData } =
    mallProductMasterContext || {};
  let [mallProductDataSelected, setMallProductDataSelected] = useState<any>([]);
  let [mallProductDataUnselected, setMallProductDataUnselected] = useState<any>(
    [],
  );
  let [productSendDataSelected, setProductSentDataSelected] = useState<any>([]);
  let [stockSentDataSelected, setStockSentDataSelected] = useState<any>([]);
  const [tabPanelCurrent, setTabPanelCurrent] = useState(t('Not Selected'));
  const [tabPanelIndex, setTabPanelIndex] = useState<any>(null);
  const [popupConfirmType, setPopupConfirmType] = useState('');

  const dataGridUnselectRef: any = useRef(null);
  const dataGridRef: any = useRef(null);
  const dataGridProdRef: any = useRef(null);
  const dataGridStockRef: any = useRef(null);

  const dataGridUnselectInstance: any = dataGridUnselectRef?.current?.instance;
  const dataGridInstance: any = dataGridRef?.current?.instance;
  const dataGridProdInstance: any = dataGridProdRef?.current?.instance;
  const dataGridStockInstance: any = dataGridStockRef?.current?.instance;
  const selectedKeys = dataGridInstance?.option().selectedRowKeys;
  const selectedProdKeys = dataGridProdInstance?.option().selectedRowKeys;
  const selectedStockKeys = dataGridStockInstance?.option().selectedRowKeys;

  const { DateFormat, DateTimeFormat } = useFormatTime();
  const { QtyFormat, DoubleFormat } = useFormat();

  const dataUnselectStore: any = new ArrayStore({
    data: mallProductDataUnselected,
    key: ['mallCd', 'productId', 'optionId'],
  });

  const dataSelectedStore: any = new ArrayStore({
    data: mallProductDataSelected,
    key: ['mallCd', 'productId', 'optionId'],
  });

  const dataProductSentStore: any = new ArrayStore({
    data: productSendDataSelected,
    key: ['mallCd', 'productId', 'optionId'],
  });

  const dataStockSentStore: any = new ArrayStore({
    data: stockSentDataSelected,
    key: ['mallCd', 'productId', 'optionId'],
  });

  /**
   * render status
   *
   * @param {*} record
   * @return {*}
   */
  const renderStatus = (record: any) => {
    switch (record?.value) {
      case ROW_STATUS.INSERTED:
        return '+';
      case ROW_STATUS.UPDATED:
        return 'V';
      case ROW_STATUS.DELETED:
        return '-';
      default:
        return null;
    }
  };

  /**
   * on set cell product
   *
   * @param {*} newData
   * @param {*} value
   * @param {*} currentRowData
   */
  const setCellProductInterlockVal = (newData, value, currentRowData) => {
    newData.productInterlock = value;
    if (!value) {
      newData.stockInterLock = false;
    }
  };

  /**
   * on set cell stock
   *
   * @param {*} newData
   * @param {*} value
   * @param {*} currentRowData
   */
  const setCellStockInterlockVal = (newData, value, currentRowData) => {
    newData.stockInterLock = value;
    if (!currentRowData?.productInterlock) {
      newData.stockInterLock = false;
      notification({
        message: t('You have to check in Product Interlock fisrt'),
        type: 'error',
      });
    }
  };

  /**
   * on row unselect
   *
   * @param {*} e
   * @return {*}
   */
  const onRowUnselectUpdating = e => {
    if (
      e?.newData?.stockInterLock === true ||
      e?.newData?.stockInterLock === false
    ) {
      if (!e?.oldData?.productInterlock) return;
    }
    if (!e?.newData?.status) {
      const keyNewData = Object.keys(e?.newData)[0];
      dataUnselectStore.update(
        {
          mallCd: e?.oldData?.mallCd,
          productId: e?.oldData?.productId,
          optionId: e?.oldData?.optionId,
        },
        {
          status: ROW_STATUS.UPDATED,
          keyNewData: e?.newData[keyNewData],
        },
      );
    }
  };

  /**
   * on row select
   *
   * @param {*} e
   * @return {*}
   */
  const onRowSelectedUpdating = e => {
    if (
      e?.newData?.stockInterLock === true ||
      e?.newData?.stockInterLock === false
    ) {
      if (!e?.oldData?.productInterlock) return;
    }
    if (!e?.newData?.status) {
      const keyNewData = Object.keys(e?.newData)[0];
      dataSelectedStore.update(
        {
          mallCd: e?.oldData?.mallCd,
          productId: e?.oldData?.productId,
          optionId: e?.oldData?.optionId,
        },
        {
          status: ROW_STATUS.UPDATED,
          keyNewData: e?.newData[keyNewData],
        },
      );
    }
  };

  /**
   * on row product sent
   *
   * @param {*} e
   * @return {*}
   */
  const onRowProductSentUpdating = e => {
    if (
      e?.newData?.stockInterLock === true ||
      e?.newData?.stockInterLock === false
    ) {
      if (!e?.oldData?.productInterlock) return;
    }
    if (!e?.newData?.status) {
      const keyNewData = Object.keys(e?.newData)[0];
      dataProductSentStore.update(
        {
          mallCd: e?.oldData?.mallCd,
          productId: e?.oldData?.productId,
          optionId: e?.oldData?.optionId,
        },
        {
          status: ROW_STATUS.UPDATED,
          keyNewData: e?.newData[keyNewData],
        },
      );
    }
  };

  /**
   * on deselect all
   *
   */
  const deselectAll = () => {
    setTimeout(() => {
      dataGridInstance?.clearSelection?.();
      dataGridUnselectInstance?.clearSelection?.();
      dataGridProdInstance?.clearSelection?.();
      dataGridStockInstance?.clearSelection?.();
    }, 100);
  };

  /**
   * on save
   *
   * @param {string} type
   * @return {*}
   */
  const onSaving = async (type: string) => {
    let mallStocksList: any = [];
    let mallStockDeleteIds: any = [];
    const dataProductSentUpdate = productSendDataSelected.filter(
      item => item?.status === ROW_STATUS.UPDATED,
    );
    const dataSelectedUpdate = mallProductDataSelected.filter(
      item => item?.status === ROW_STATUS.UPDATED,
    );

    mallStockDeleteIds = mallProductDataSelected
      .filter((o: any) => o?.status === ROW_STATUS.DELETED)
      .map((o: any) => o?.mallStockId);

    if (type === 'unSelect') {
      const rowsDataSelected = dataGridUnselectInstance?.getSelectedRowsData();
      mallStocksList = rowsDataSelected.concat(
        dataSelectedUpdate,
        dataProductSentUpdate,
      );

      if (mallStocksList?.length === 0) {
        notification({
          message: t('Please select at least a record to save!'),
          type: 'error',
        });
        return;
      }
    } else {
      mallStocksList = dataSelectedUpdate.concat(dataProductSentUpdate);
    }

    const isProductChecked = mallStocksList.some(
      item => item?.productInterlock,
    );
    const isStockChecked = mallStocksList.some(item => item?.stockInterLock);

    const res = await updateMallProduct({
      data: {
        list: mallStocksList,
        mallStockDeleteIds: mallStockDeleteIds,
      },
    });

    notification({
      res,
    });

    if (res?.data?.status === '200' || res?.data?.status === '201') {
      const refetchRes = await refetchData({ data: { ...params } });
      if (
        refetchRes?.data?.status === '200' ||
        refetchRes?.data?.status === '201'
      ) {
        if (isProductChecked && !isStockChecked) {
          setTabPanelCurrent(t('Product Sent'));
          setTabPanelIndex(2);
        } else if (isProductChecked && isStockChecked) {
          setTabPanelCurrent(t('Stock Sent'));
          setTabPanelIndex(3);
        }
      }
    }
  };

  // Revert origin data after change
  const getOriginData = () => {
    if (mallProductData) {
      const mallProductDataClone = JSON.parse(JSON.stringify(mallProductData));
      const selectedList = mallProductDataClone?.selectedList || [];
      const productSelectedData = selectedList.filter(
        item => !item?.productInterlock && !item?.stockInterLock,
      );
      const productSentData = selectedList.filter(
        item => item?.productInterlock && !item?.stockInterLock,
      );
      const stockSentData = selectedList.filter(
        item => item?.productInterlock && item?.stockInterLock,
      );
      setMallProductDataSelected(productSelectedData);
      setProductSentDataSelected(productSentData);
      setStockSentDataSelected(stockSentData);
      setMallProductDataUnselected(mallProductDataClone?.unSelectedList);
    }
  };

  /**
   * on cancel
   *
   */
  const onCancel = () => {
    setPopupConfirmType('cancel');
  };

  /**
   * on confirm cancel
   *
   */
  const handleConfirmCancel = async () => {
    await getOriginData();
    await deselectAll();
    setPopupConfirmType('');
  };

  const onDelete = () => {
    let rowsDataSelected = [];
    switch (tabPanelCurrent) {
      case t('Selected'):
        rowsDataSelected = dataGridInstance?.getSelectedRowsData();
        break;
      case t('Product Sent'):
        rowsDataSelected = dataGridProdInstance?.getSelectedRowsData();
        break;
      case t('Stock Sent'):
        rowsDataSelected = dataGridStockInstance?.getSelectedRowsData();
        break;
      default:
        break;
    }
    if (rowsDataSelected?.length === 0) {
      notification({
        message: t('Please select at least a record to delete!'),
        type: 'error',
      });
      return;
    }
    setPopupConfirmType('delete');
  };

  /**
   * on confirm delete
   *
   * @return {*}
   */
  const handleConfirmDelete = async () => {
    const rowsDataSelected = dataGridInstance?.getSelectedRowsData();
    const isProductInterlockChecked = rowsDataSelected.some(
      item => item?.productInterlock,
    );
    if (isProductInterlockChecked) {
      notification({
        message: t(
          'Product Interlock has been checked. Please uncheck to delete!',
        ),
        type: 'error',
      });
      return;
    }
    // Check selected all data to delete
    if (rowsDataSelected?.length === dataGridInstance?.totalCount()) {
      notification({
        message: t('You need select at least a record in seleted tab!'),
        type: 'error',
      });
      return;
    }
    for (let i = 0; i < rowsDataSelected.length; i++) {
      const item = rowsDataSelected[i];
      dataSelectedStore.update(
        {
          mallCd: item?.mallCd,
          productId: item?.productId,
          optionId: item?.optionId,
        },
        {
          status: ROW_STATUS.DELETED,
        },
      );
    }
    setPopupConfirmType('');
  };

  /**
   * on change interlock
   *
   * @param {string} column
   * @param {boolean} type
   * @return {*}
   */
  const onChangeInterlockAllSelected = (column: string, type: boolean) => {
    if (mallProductDataSelected?.length === 0) return;
    switch (column) {
      case 'productInterlock':
        for (let i = 0; i < mallProductDataSelected.length; i++) {
          const item = mallProductDataSelected[i];
          if (type) {
            if (item?.productInterlock === true) continue;
            dataSelectedStore.update(
              {
                mallCd: item?.mallCd,
                productId: item?.productId,
                optionId: item?.optionId,
              },
              {
                productInterlock: type,
                status: ROW_STATUS.UPDATED,
              },
            );
          } else {
            if (item?.productInterlock === false) continue;
            dataSelectedStore.update(
              {
                mallCd: item?.mallCd,
                productId: item?.productId,
                optionId: item?.optionId,
              },
              {
                productInterlock: type,
                stockInterLock: false,
                status: ROW_STATUS.UPDATED,
              },
            );
          }
        }
        dataGridInstance?.refresh?.();
        break;
      case 'stockInterLock':
        if (tabPanelCurrent === t('Product Sent')) {
          for (let i = 0; i < productSendDataSelected.length; i++) {
            const item = productSendDataSelected[i];
            if (type) {
              if (item?.stockInterLock === true) continue;
            } else {
              if (item?.stockInterLock === false) continue;
            }
            dataProductSentStore.update(
              {
                mallCd: item?.mallCd,
                productId: item?.productId,
                optionId: item?.optionId,
              },
              {
                stockInterLock: type,
                status: ROW_STATUS.UPDATED,
              },
            );
          }
          dataGridProdInstance?.refresh?.();
          return;
        }
        const isProductInterlockChecked = mallProductDataSelected.some(
          item => !item?.productInterlock,
        );

        if (isProductInterlockChecked) {
          notification({
            message: t('You have to check in Product Interlock fisrt'),
            type: 'error',
          });
          return;
        }
        for (let i = 0; i < mallProductDataSelected.length; i++) {
          const item = mallProductDataSelected[i];
          if (type) {
            if (item?.stockInterLock === true) continue;
          } else {
            if (item?.stockInterLock === false) continue;
          }
          dataSelectedStore.update(
            {
              mallCd: item?.mallCd,
              productId: item?.productId,
              optionId: item?.optionId,
            },
            {
              stockInterLock: type,
              status: ROW_STATUS.UPDATED,
            },
          );
        }
        dataGridInstance?.refresh?.();
        break;
      default:
        break;
    }
  };

  /**
   * on change view data
   *
   * @param {*} mallProductData
   */
  const onChangeViewData = (mallProductData: any) => {
    const mallProductDataClone = JSON.parse(JSON.stringify(mallProductData));
    const selectedList = mallProductDataClone?.selectedList || [];
    const productSelectedData = selectedList.filter(
      item => !item?.productInterlock && !item?.stockInterLock,
    );
    const productSentData = selectedList.filter(
      item => item?.productInterlock && !item?.stockInterLock,
    );
    const stockSentData = selectedList.filter(
      item => item?.productInterlock && item?.stockInterLock,
    );
    setMallProductDataSelected(productSelectedData);
    setProductSentDataSelected(productSentData);
    setStockSentDataSelected(stockSentData);
    setMallProductDataUnselected(mallProductDataClone?.unSelectedList);
  };

  /**
   * on change tab
   *
   * @param {string} title
   */
  const onChangeTabPanel = (title: string) => {
    setTabPanelIndex(null);
    setTabPanelCurrent(title);
    deselectAll();
  };

  /**
   * get show popup confirm
   *
   * @param {string} popupConfirmType
   * @return {*}
   */
  const getShowPopupConfirm = (popupConfirmType: string) => {
    let title = '';
    let content = '';
    let handleFunc = () => {};
    switch (popupConfirmType) {
      case 'cancel':
        title = t('Confirm Cancel');
        content = t('Are you sure to cancel all the change?');
        handleFunc = handleConfirmCancel;
        break;
      case 'delete':
        title = t('Confirm Delete');
        content = `${t('Do you want to delete {0} items?')}`.replace(
          '{0}',
          selectedKeys?.length ||
            selectedProdKeys?.length ||
            selectedStockKeys?.length,
        );
        handleFunc = handleConfirmDelete;
        break;
      default:
        break;
    }
    return (
      <PopupConfirm
        title={title}
        visible={popupConfirmType !== ''}
        content={content}
        onOk={handleFunc}
        onHiding={() => setPopupConfirmType('')}
      />
    );
  };

  useEffect(() => {
    if (mallProductData) {
      onChangeViewData(mallProductData);
    }
  }, [mallProductData]);

  const columns: any = [
    {
      dataField: 'status',
      caption: t('Status'),
      alignment: 'center',
      cellRender: renderStatus,
      width: 70,
      fixedPosition: 'left',
      fixed: true,
      allowHeaderFiltering: false,
      allowFiltering: false,
      allowEditing: false,
    },
    {
      dataField: 'productId',
      caption: t('ID'),
      allowEditing: false,
      fixedPosition: 'left',
      fixed: true,
      visible: tabPanelCurrent !== t('Not Selected') ? true : false,
    },
    {
      dataField: 'productCode',
      caption: t('Product Code'),
      allowEditing: false,
      fixedPosition: 'left',
      fixed: true,
    },
    {
      dataField: 'productName',
      caption: t('Product Name'),
      allowEditing: false,
      fixedPosition: 'left',
      fixed: true,
    },
    {
      dataField: 'marketableSize',
      caption: t('Marketable Size'),
      allowEditing: false,
    },
    {
      dataField: 'optionCd',
      caption: t('Option Code'),
      allowEditing: false,
    },
    {
      dataField: 'optionNm',
      caption: t('Option'),
      allowEditing: false,
    },
    {
      dataField: 'quantityPerPack',
      caption: t('Quantity Per Pack'),
      format: QtyFormat,
      dataType: 'number',
      allowEditing: false,
    },
    {
      dataField: 'productSku',
      caption: t('SKU'),
      allowEditing: false,
    },
    {
      dataField: 'registrationDate',
      caption: t('Registration Date'),
      dataType: 'date',
      format: DateFormat,
      allowEditing: false,
    },
    {
      dataField: 'productChangeDate',
      caption: t('Product Change Date'),
      dataType: 'date',
      format: DateFormat,
      allowEditing: false,
    },
    {
      dataField: 'basePrice',
      caption: t('Base Price'),
      format: DoubleFormat,
      dataType: 'number',
      allowEditing: false,
    },
    {
      dataField: 'addPrice',
      caption: t('Add Price'),
      format: DoubleFormat,
      dataType: 'number',
      allowEditing: false,
    },
    {
      dataField: 'productInterlock',
      caption: t('Product Interlock'),
      dataType: 'boolean',
      trueText: t('Active'),
      falseText: t('Inactive'),
      setCellValue: setCellProductInterlockVal,
      visible:
        tabPanelCurrent === t('Selected') ||
        tabPanelCurrent === t('Not Selected')
          ? true
          : false,
    },
    {
      dataField: 'stockInterLock',
      caption: t('Stock Interlock'),
      dataType: 'boolean',
      trueText: t('Active'),
      falseText: t('Inactive'),
      setCellValue: setCellStockInterlockVal,
      visible: tabPanelCurrent !== t('Stock Sent') ? true : false,
    },
    {
      dataField: 'sendProductDt',
      caption: t('Send Product Datetime'),
      dataType: 'date',
      format: DateTimeFormat,
      allowEditing: false,
      visible:
        tabPanelCurrent === t('Stock Sent') ||
        tabPanelCurrent === t('Product Sent')
          ? true
          : false,
    },
    {
      dataField: 'sendProductResult',
      caption: t('Send Product Result'),
      allowEditing: false,
      visible:
        tabPanelCurrent === t('Stock Sent') ||
        tabPanelCurrent === t('Product Sent')
          ? true
          : false,
    },
    {
      dataField: 'sendStockDt',
      caption: t('Send Stock Datetime'),
      dataType: 'date',
      format: DateTimeFormat,
      allowEditing: false,
      visible: tabPanelCurrent === t('Stock Sent') ? true : false,
    },
    {
      dataField: 'sendStockResult',
      caption: t('Send Stock Result'),
      allowEditing: false,
      visible: tabPanelCurrent === t('Stock Sent') ? true : false,
    },
  ];

  return (
    <>
      <FormSearch />
      <TabPanel
        defaultSelectedIndex={0}
        selectedIndex={tabPanelIndex}
        onSelectionChanged={(e: any) => {
          onChangeTabPanel(e?.addedItems[0].title);
        }}
        className="body-padding-white"
      >
        <TabItem title={t('Not Selected')}>
          <div style={themePro ? {} : { padding: '20px' }}>
            <DataGridFull
              columns={columns}
              ref={dataGridUnselectRef}
              dataSource={dataUnselectStore}
              options={{
                keyExpr: ['mallCd', 'productId', 'optionId'],
                onRowUpdating: onRowUnselectUpdating,
                editing: {
                  mode: 'cell',
                  allowUpdating: true,
                  selectTextOnEditStart: true,
                },
                selection: {
                  mode: 'multiple',
                  selectAllMode: 'allPages',
                  allowSelectAll: false,
                },
                columnAutoWidth: true,
                onToolbarPreparing: (e: any) => {
                  e.toolbarOptions.items.unshift(
                    {
                      location: 'before',
                      template: 'totalCount',
                    },
                    {
                      location: 'after',
                      widget: 'dxButton',
                      options: {
                        icon: 'save',
                        text: t('Save'),
                        onClick: () => onSaving('unSelect'),
                        disabled: mallProductDataUnselected?.length === 0,
                      },
                    },
                    {
                      location: 'after',
                      widget: 'dxButton',
                      options: {
                        icon: themePro ? 'close' : 'revert',
                        text: t('Cancel'),
                        onClick: () => onCancel(),
                        disabled: mallProductDataUnselected?.length === 0,
                      },
                    },
                    {
                      location: 'after',
                      widget: 'dxButton',
                      options: {
                        icon: 'trash',
                        text: t('Delete'),
                        onClick: onDelete,
                        disabled: true,
                      },
                    },
                  );
                },
              }}
            />
          </div>
        </TabItem>
        <TabItem title={t('Selected')}>
          <div style={themePro ? {} : { padding: '20px' }}>
            <DataGridFull
              columns={columns}
              ref={dataGridRef}
              dataSource={dataSelectedStore}
              options={{
                keyExpr: ['mallCd', 'productId', 'optionId'],
                onRowUpdating: onRowSelectedUpdating,
                editing: {
                  mode: 'cell',
                  allowUpdating: true,
                  selectTextOnEditStart: true,
                },
                selection: {
                  mode: 'multiple',
                  selectAllMode: 'allPages',
                },
                columnAutoWidth: true,
                onToolbarPreparing: (e: any) => {
                  e.toolbarOptions.items.unshift(
                    {
                      location: 'before',
                      template: 'totalCount',
                    },
                    {
                      location: 'after',
                      widget: 'dxButton',
                      options: {
                        icon: 'save',
                        text: t('Save'),
                        onClick: () => onSaving('selected'),
                        disabled: mallProductDataSelected?.length === 0,
                      },
                    },
                    {
                      location: 'after',
                      widget: 'dxButton',
                      options: {
                        icon: themePro ? 'close' : 'revert',
                        text: t('Cancel'),
                        onClick: () => onCancel(),
                        disabled: mallProductDataSelected?.length === 0,
                      },
                    },
                    {
                      location: 'after',
                      widget: 'dxButton',
                      options: {
                        icon: 'trash',
                        text: t('Delete'),
                        onClick: onDelete,
                        disabled: mallProductDataSelected?.length === 0,
                      },
                    },
                  );
                },
                onContextMenuPreparing: (e: any) => {
                  if (
                    e.column.name === 'productInterlock' ||
                    e.column.name === 'stockInterLock'
                  ) {
                    e.items.push(
                      {
                        text: t('Check All'),
                        onItemClick: () =>
                          onChangeInterlockAllSelected(e.column.name, true),
                      },
                      {
                        text: t('Uncheck All'),
                        onItemClick: () =>
                          onChangeInterlockAllSelected(e.column.name, false),
                      },
                    );
                  }
                },
              }}
            />
          </div>
        </TabItem>
        <TabItem title={t('Product Sent')}>
          <div style={themePro ? {} : { padding: '20px' }}>
            <DataGridFull
              columns={columns}
              ref={dataGridProdRef}
              dataSource={dataProductSentStore}
              options={{
                keyExpr: ['mallCd', 'productId', 'optionId'],
                onRowUpdating: onRowProductSentUpdating,
                editing: {
                  mode: 'cell',
                  allowUpdating: true,
                  selectTextOnEditStart: true,
                },
                selection: {
                  mode: 'multiple',
                  selectAllMode: 'allPages',
                },
                columnAutoWidth: true,
                onToolbarPreparing: (e: any) => {
                  e.toolbarOptions.items.unshift(
                    {
                      location: 'before',
                      template: 'totalCount',
                    },
                    {
                      location: 'after',
                      widget: 'dxButton',
                      options: {
                        icon: 'save',
                        text: t('Save'),
                        onClick: () => onSaving('selected'),
                        disabled: productSendDataSelected?.length === 0,
                      },
                    },
                    {
                      location: 'after',
                      widget: 'dxButton',
                      options: {
                        icon: themePro ? 'close' : 'revert',
                        text: t('Cancel'),
                        onClick: () => onCancel(),
                        disabled: productSendDataSelected?.length === 0,
                      },
                    },
                    {
                      location: 'after',
                      widget: 'dxButton',
                      options: {
                        icon: 'trash',
                        text: t('Delete'),
                        onClick: onDelete,
                        disabled: true,
                      },
                    },
                  );
                },
                onContextMenuPreparing: (e: any) => {
                  if (
                    e.column.name === 'productInterlock' ||
                    e.column.name === 'stockInterLock'
                  ) {
                    e.items.push(
                      {
                        text: t('Check All'),
                        onItemClick: () =>
                          onChangeInterlockAllSelected(e.column.name, true),
                      },
                      {
                        text: t('Uncheck All'),
                        onItemClick: () =>
                          onChangeInterlockAllSelected(e.column.name, false),
                      },
                    );
                  }
                },
              }}
            />
          </div>
        </TabItem>
        <TabItem title={t('Stock Sent')}>
          <div style={themePro ? {} : { padding: '20px' }}>
            <DataGridFull
              columns={columns}
              ref={dataGridStockRef}
              dataSource={dataStockSentStore}
              options={{
                keyExpr: ['mallCd', 'productId', 'optionId'],
                selection: {
                  mode: 'multiple',
                  selectAllMode: 'allPages',
                },
                columnAutoWidth: true,
                onToolbarPreparing: (e: any) => {
                  e.toolbarOptions.items.unshift(
                    {
                      location: 'before',
                      template: 'totalCount',
                    },
                    {
                      location: 'after',
                      widget: 'dxButton',
                      options: {
                        icon: 'save',
                        text: t('Save'),
                        onClick: () => onSaving('selected'),
                        disabled: stockSentDataSelected?.length === 0,
                      },
                    },
                    {
                      location: 'after',
                      widget: 'dxButton',
                      options: {
                        icon: themePro ? 'close' : 'revert',
                        text: t('Cancel'),
                        onClick: () => onCancel(),
                        disabled: stockSentDataSelected?.length === 0,
                      },
                    },
                    {
                      location: 'after',
                      widget: 'dxButton',
                      options: {
                        icon: 'trash',
                        text: t('Delete'),
                        onClick: onDelete,
                        disabled: true,
                      },
                    },
                  );
                },
              }}
            />
          </div>
        </TabItem>
      </TabPanel>
      {getShowPopupConfirm(popupConfirmType)}
    </>
  );
}

export default ListMall;
