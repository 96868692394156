/**
 * Update date: 08-06-2023
 * Header component
 */
import { useApp } from 'app';
import useAxios from 'axios-hooks';
import { LANG_DEFAULT } from 'constant';
import { Button } from 'devextreme-react';
import { locale } from 'devextreme/localization';
import useCenterInfo from 'hooks/useCenterInfo';
import useDictionary from 'hooks/useDictionary';
import useWindowSize from 'hooks/useWindowSize';
import { avatarDefault, iconChat, iconSetting } from 'images';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { authActions } from 'store/features/auth/authSlice';
import { LayoutContext, useLayout } from '../Layout';
import LoadPanel from '../LoadPanel';
import { NotificationPopover } from '../Notification/NotificationPopover';
import SwitchTheme from '../SwitchTheme';
import NavLeft from './NavLeft';
import './styles.scss';

function Header() {
  const { centerName } = useCenterInfo();
  const { isLoggedIn, showOtherPage, setShowOtherPage }: any = useLayout();
  const myAppContext: any = useApp();
  const { myPageApp } = myAppContext || {};
  const [isShowMyPagePopup, setIsShowMyPagePopup] = useState<boolean>(false);
  const { t } = useDictionary({});
  const dispatch = useDispatch();
  const windowSize: any = useWindowSize();
  const smallSize = windowSize?.width < 768;
  const history = useHistory();
  const [dashboard, setDashboard] = useState(false);
  const layoutContext: any = useContext(LayoutContext);
  const { listLastUsed, mainMenu, setListLastUsed } = layoutContext;
  const location: any = useLocation();
  const { programId3 } = location.state || {};
  const [{ loading: loadingGetDashboard }, refetchGetDashboard] = useAxios(
    {
      url: '/user/chart/user-dash-board',
      method: 'POST',
    },
    { useCache: false, autoCancel: true },
  );

  // const [{ loading: loadingLastUsed }, refetchLastUsed] = useAxios(
  //   {
  //     url: 'user/user/program-history',
  //     method: 'GET',
  //   },
  //   {
  //     manual: true,
  //   },
  // );
  // const [{ loading: loadingDeleteLastUsed }, refetchDeleteLastUsed] = useAxios(
  //   {
  //     url: 'user/user/program-history',
  //     method: 'DELETE',
  //   },
  //   {
  //     manual: true,
  //   },
  // );

  /**
   * on open setting
   *
   */
  const onShowSettingPopup = () => {
    setIsShowMyPagePopup(true);
    setShowOtherPage('myPage');
  };

  /**
   * on close setting
   *
   */
  const closePopup = () => {
    setIsShowMyPagePopup(false);
    setShowOtherPage('');
  };

  /**
   * on logout
   *
   */
  const onLogout = () => {
    const lang =
      JSON.parse(localStorage.getItem('myPagePreference') || '{}')?.language ||
      LANG_DEFAULT;
    locale(lang);
    dispatch(authActions.logout());
    setShowOtherPage('');
  };
  /**
   * fetch data
   */

  useEffect(() => {
    const fetchData = async () => {
      const myPagePreferenceLocal = JSON.parse(
        localStorage.getItem('myPagePreference') || '{}',
      );
      const resDashboard = await refetchGetDashboard({
        data: {
          userId: myPagePreferenceLocal?.userId,
        },
      });
      const dbRes = resDashboard?.data?.data;
      setDashboard(!!dbRes);
    };
    fetchData();
  }, []);

  return (
    <header className="header dx-background-customize">
      <LoadPanel visible={loadingGetDashboard} />
      <Helmet>
        <title>
          {listLastUsed?.find(o => o?.programId === programId3)?.programName ||
            centerName}
        </title>
        <meta name="description" content={programId3} />
      </Helmet>
      {!showOtherPage ? (
        <div className="header-design">
          <div className="header-button-last-used">
            {listLastUsed?.slice(0, 10)?.map((o, i) => (
              <div
                className={`button-last-used ${
                  o?.programId === programId3 && 'button-last-used-active'
                }`}
                key={i}
                onClick={() => {
                  if (o?.programId === programId3) return;
                  let breakFor = false;
                  for (let i = 0; i < mainMenu.length && !breakFor; i++) {
                    const subMenu = mainMenu[i]?.children;
                    for (let j = 0; j < subMenu.length && !breakFor; j++) {
                      const programMenu = subMenu[j]?.children;
                      for (
                        let x = 0;
                        x < programMenu.length && !breakFor;
                        x++
                      ) {
                        if (o?.programId === programMenu[x]?.programId) {
                          const url = [
                            '',
                            mainMenu[i]?.programId,
                            subMenu[j]?.programId,
                            o?.programId,
                          ].join('/');
                          history.push(url, {
                            programId1: mainMenu[i]?.programId,
                            programId2: subMenu[j]?.programId,
                            programId3: o?.programId,
                            notUpdateHistory: true,
                          });
                          breakFor = true;
                          break;
                        }
                      }
                      if (breakFor) break;
                    }
                    if (breakFor) break;
                  }
                }}
              >
                <div className="button-last-used-content">
                  <span className="dx-button-text">{o?.programName}</span>
                </div>
                <i
                  className="dx-icon dx-icon-close"
                  onClick={e => {
                    e.stopPropagation();

                    if (o?.programId === programId3) {
                      const index = listLastUsed?.findIndex(
                        o => o?.programId === programId3,
                      );
                      const nextUsed =
                        listLastUsed[index + 1] || listLastUsed[0] || null;

                      if (!nextUsed || listLastUsed?.length === 1) {
                        setListLastUsed([]);
                        return history.push('/');
                      }
                      let breakFor = false;
                      for (let i = 0; i < mainMenu.length && !breakFor; i++) {
                        const subMenu = mainMenu[i]?.children;
                        for (let j = 0; j < subMenu.length && !breakFor; j++) {
                          const programMenu = subMenu[j]?.children;
                          for (
                            let x = 0;
                            x < programMenu.length && !breakFor;
                            x++
                          ) {
                            if (
                              nextUsed?.programId === programMenu[x]?.programId
                            ) {
                              const url = [
                                '',
                                mainMenu[i]?.programId,
                                subMenu[j]?.programId,
                                nextUsed?.programId,
                              ].join('/');
                              history.push(url, {
                                programId1: mainMenu[i]?.programId,
                                programId2: subMenu[j]?.programId,
                                programId3: nextUsed?.programId,
                                notUpdateHistory: true,
                              });
                              breakFor = true;
                              break;
                            }
                          }
                          if (breakFor) break;
                        }
                        if (breakFor) break;
                      }
                    }
                    setListLastUsed(presState =>
                      presState.filter(f => f?.programId !== o?.programId),
                    );
                    // refetchDeleteLastUsed({
                    //   params: {
                    //     id: o?.id,
                    //   },
                    // });
                    // const res: any = await refetchLastUsed({});
                    // setListLastUsed(res?.data?.data || []);
                  }}
                ></i>
              </div>
            ))}
          </div>
          <div className="header-design-settings">
            <SwitchTheme />
            <span className="cursor-pointer">
              <img height={20} width={20} src={iconChat} alt="setting" />
            </span>
            <span onClick={onShowSettingPopup} className="cursor-pointer">
              <img height={20} width={20} src={iconSetting} alt="setting" />
            </span>
            <span className="cursor-pointer" id="notification-alarm">
              <NotificationPopover />
            </span>
            <span>
              <img
                height={40}
                width={40}
                style={{ borderRadius: 50 }}
                src={myPageApp?.avatar || avatarDefault}
                alt="avatar"
              />
            </span>
            <span
              onClick={() => {
                const lang =
                  JSON.parse(localStorage.getItem('myPagePreference') || '{}')
                    ?.language || LANG_DEFAULT;
                locale(lang);
                dispatch(authActions.logout());
                setListLastUsed(preState => preState.slice(0, 1));
              }}
              className="text-header cursor-pointer"
            >
              | {t('Logout')}
            </span>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 45px 5px 25px',
          }}
        >
          <span
            style={{
              color: '#667085',
              fontSize: 20,
              fontWeight: 600,
              textTransform: 'capitalize',
            }}
          >
            {showOtherPage.replace(/([A-Z])/g, ' $1').trim()}
          </span>
          <div>
            <Button
              text={t('Logout')}
              icon="fas fa-sign-out"
              onClick={onLogout}
              style={{ marginRight: 5 }}
            />
            <Button text={t('Cancel')} icon="close" onClick={closePopup} />
          </div>
        </div>
      )}
      {smallSize && isLoggedIn && (
        <div className="mobile-header-narbar">
          <NavLeft />
        </div>
      )}
    </header>
  );
}

export default Header;
