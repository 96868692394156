import axios from 'axios';
import {
  BASIC_KEY,
  HOST_CLOUD,
  TYPE_TOKEN,
  ERROR_STATUS,
  ERORR_UNAUTHORIZED,
  SUCCESS_STATUS,
} from 'constant';
import tokenService from 'services/auth.service';
import { store } from 'store/configureStore';
import { authActions } from 'store/features/auth/authSlice';
import { configure } from 'axios-hooks';
import LRU from 'lru-cache';
import { locale } from 'devextreme/localization';
import listLang from '../utils/language.json';

const getLangCode = () =>
  listLang?.list?.filter(o => o?.value === locale())?.[0]?.code || 'en-En';
// https://staging.api.smdc-dev.com
const api = axios.create({
  baseURL: `${HOST_CLOUD}`,
  // timeout: 6000,
  headers: {
    // 'Content-Type': 'multipart/form-data',
    Authorization: BASIC_KEY,
  },
});

api.interceptors.request.use(
  config => {
    const token = tokenService.getLocalAccessToken();
    if (token) {
      if (config.url !== 'oauth/token') {
        config.headers['Authorization'] = `${TYPE_TOKEN} ${token}`;
        config.headers['Accept-Language'] = getLangCode();
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    const originalConfig = err.config;
    if (
      err.response &&
      err.response.status === 401 &&
      originalConfig.url !== 'oauth/token'
    ) {
      //Logout
      store.dispatch(authActions.logout());
    }

    return Promise.reject(err);
  },
);

const cache = new LRU({ max: 10 });

configure({ axios: api, cache });

const apiWithoutBasicKey = axios.create({
  baseURL: `${HOST_CLOUD}`,
  timeout: 6000,
});

const sendGet = (url: string, params?: any) => {
  const options: any = {
    headers: {
      Authorization: `${TYPE_TOKEN} ${tokenService.getLocalAccessToken()}`,
      params,
    },
  };
  return apiWithoutBasicKey
    .get(url, options)
    .then(res => {
      if (ERROR_STATUS.includes(+res.data.status)) {
        throw res;
      } else {
        return res;
      }
    })
    .catch(err => {
      if (err.status === SUCCESS_STATUS) {
        throw err;
      } else if (ERORR_UNAUTHORIZED === +err.response.status) {
        store.dispatch(authActions.logout());
      }
      throw err;
    });
};

const sendGetResponseTypeNotJson = (url: string, params: any, responseType) => {
  const options: any = {
    headers: {
      Authorization: `${TYPE_TOKEN} ${tokenService.getLocalAccessToken()}`,
      params,
    },
    responseType: responseType,
  };
  return apiWithoutBasicKey
    .get(url, options)
    .then(res => {
      if (ERROR_STATUS.includes(+res.data.status)) {
        throw res;
      } else {
        return res;
      }
    })
    .catch(err => {
      throw err;
    });
};

const sendPost = (url: string, params: any) => {
  const options = {
    headers: {
      Authorization: `${TYPE_TOKEN} ${tokenService.getLocalAccessToken()}`,
    },
  };
  return apiWithoutBasicKey
    .post(url, params, options)
    .then(res => {
      return res;
    })
    .catch(err => {
      if (ERORR_UNAUTHORIZED === +err.response.status) {
        store.dispatch(authActions.logout());
      }
      const data = err?.response?.data || {};
      return { ...data, message: data?.error || 'Server Error!' };
    });
};

const sendPut = (url: string, params: any) => {
  const options = {
    headers: {
      Authorization: `${TYPE_TOKEN} ${tokenService.getLocalAccessToken()}`,
    },
  };
  return apiWithoutBasicKey
    .put(url, params, options)
    .then(res => {
      if (ERROR_STATUS.includes(+res.data.status)) {
        throw res;
      } else {
        return res;
      }
    })
    .catch(err => {
      if (err.status === SUCCESS_STATUS) {
        throw err;
      } else if (ERORR_UNAUTHORIZED === +err.response.status) {
        store.dispatch(authActions.logout());
      }
      throw err;
    });
};

const sendDelete = (url: string, params?: any) => {
  const options = {
    headers: {
      Authorization: `${TYPE_TOKEN} ${tokenService.getLocalAccessToken()}`,
    },
    data: params,
  };
  return apiWithoutBasicKey
    .delete(url, options)
    .then(res => {
      if (ERROR_STATUS.includes(+res.data.status)) {
        throw res;
      } else {
        return res;
      }
    })
    .catch(err => {
      if (err.status === SUCCESS_STATUS) {
        throw err;
      } else if (ERORR_UNAUTHORIZED === +err.response.status) {
        store.dispatch(authActions.logout());
      }
      throw err;
    });
};

const sendPutFetch = (url: string, params: any) => {
  const options = {
    headers: {
      Authorization: `${TYPE_TOKEN} ${tokenService.getLocalAccessToken()}`,
    },
  };
  return apiWithoutBasicKey
    .put(url, params, options)
    .then(res => {
      return res;
    })
    .catch(err => {
      if (ERORR_UNAUTHORIZED === +err.response.status) {
        store.dispatch(authActions.logout());
      }
      const data = err?.response?.data || {};
      return { ...data, message: data?.error || 'Server Error!' };
    });
};

const logout = (params: any) => sendGet('/iam/oauth/logout', params);

const registerProgramId = (params: any) => sendPut('/core/program', params);
const fetchMenuProgram = (params: any) => sendGet('/core/program', params);
const deleteProgram = (id: any) => sendDelete(`/core/program/${id}`);
const registerCenterInfo = (params: any) =>
  sendPut('/core/center-info', params);
const fetchCenterInfo = (params: any) => sendGet('/core/center-info', params);
const downloadFileExampleCenterZone = (params: any) =>
  sendGetResponseTypeNotJson('core/zone-info/download-file', params, 'blob');
const uploadFileCenterZone = (params: any) =>
  sendPost('/core/zone-info/import-excel', params);

const fetchCenterZone = (params: any) => sendPost('/core/zone-info', params);
const deleteCenterZone = (params: any) => sendDelete('/core/zone-info', params);
const getDetailCenterZone = (id: any) => sendGet(`/core/zone-info/${id}`);
const updateCenterZone = (params: any) => sendPut('/core/zone-info', params);
const fetchMainMenu = (params: any) => sendGet('/user/user/program', params);
const fetchProgramBookMark = (params: any) =>
  sendGet('/user/user/program-bookmark', params);
const bookMarkUrl = (params: any) =>
  sendPut(
    `/user/user/program?programId=${params.programId}&bookMark=${params.bookMark}`,
    {},
  );
const fetchHistory = (params: any) =>
  sendGet('user/user/program-history', params);
const addProgramHistory = (params: any) =>
  sendPost(`user/user/program-history?programId=${params.programId}`, {});
const removeProgramHistory = (params: any) =>
  sendDelete(`user/user/program-history?id=${params.id}`, {});
const reOrderBookMark = (params: any) =>
  sendPut('user/user/update-order-bookmark', params);

export {
  api,
  logout,
  sendGet,
  sendPost,
  registerProgramId,
  fetchMenuProgram,
  deleteProgram,
  registerCenterInfo,
  fetchCenterInfo,
  downloadFileExampleCenterZone,
  uploadFileCenterZone,
  fetchCenterZone,
  deleteCenterZone,
  getDetailCenterZone,
  updateCenterZone,
  fetchMainMenu,
  fetchProgramBookMark,
  bookMarkUrl,
  fetchHistory,
  addProgramHistory,
  removeProgramHistory,
  reOrderBookMark,
  sendPutFetch,
};
