/**
 * Update date: 08-06-2023
 * Collapse component
 */
import { Button } from 'devextreme-react';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import useDictionary from 'hooks/useDictionary';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import './style.scss';
interface CollapseType {
  children: any;
  onSubmit?: any;
  showCollapse?: boolean;
  swipeEnabled?: boolean;
  hideSubmit?: boolean;
  hideSubmitAndExpand?: boolean;
  defaultSelectedIndex?: number;
  onOptionChanged?: (number) => void;
}

function Collapse(
  {
    children,
    onSubmit,
    showCollapse = false,
    swipeEnabled = true,
    hideSubmit = false,
    hideSubmitAndExpand = false,
    defaultSelectedIndex = 0,
    onOptionChanged,
  }: CollapseType,
  ref,
) {
  const [collapse, setCollapse] = useState<boolean>(showCollapse);
  const [focused, setFocused] = useState<number>(0);
  const [selected, setSelected] = useState<number>(0);
  const { t }: any = useDictionary({
    programId: 'common',
  });

  useImperativeHandle(ref, () => ({
    onCollappse,
  }));

  /**
   * on conllappse
   *
   * @param {boolean} [value=true]
   */
  const onCollappse = (value = true) => {
    setCollapse(value);
  };

  useEffect(() => {
    if (defaultSelectedIndex) {
      setSelected(defaultSelectedIndex);
    }
  }, [defaultSelectedIndex]);
  return (
    <div
      className={`collapse-form-search collapse-form-search-premium-children ${
        (Array.isArray(children)
          ? children?.every(o => !o?.props?.name)
          : !children?.props?.name) && 'collapse-form-search-premium'
      }`}
    >
      <TabPanel
        defaultSelectedIndex={defaultSelectedIndex}
        onTitleClick={(e: any) => {
          if (e?.itemIndex === focused) {
            setCollapse(preState => !preState);
          } else {
            setCollapse(true);
          }
          setFocused(e?.itemIndex);
        }}
        swipeEnabled={swipeEnabled}
        onOptionChanged={e => {
          if (e?.name === 'selectedIndex') {
            setSelected(e?.value);
            onOptionChanged?.(e?.value);
          }
        }}
        selectedIndex={selected}
        className="collapse-form-search-tabpanel custom-form-search-tabpanel"
      >
        {Array.isArray(children) ? (
          children.map((map, i) => (
            <TabItem key={i} title={map?.props?.name || 'Search'}>
              <div
                className={`collapse-form ${
                  !collapse && 'collapse-form-d-none'
                }`}
              >
                {map}
              </div>
              {!hideSubmitAndExpand && (
                <div
                  style={
                    collapse
                      ? {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          borderTop: '1px solid #efefef',
                          paddingTop: 10,
                        }
                      : {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }
                  }
                >
                  {!hideSubmit ? (
                    <Button
                      icon="search"
                      text={t('Search')}
                      type="default"
                      stylingMode="contained"
                      onClick={onSubmit}
                    />
                  ) : (
                    <span />
                  )}

                  <Button
                    type="back"
                    stylingMode="outlined"
                    icon={!collapse ? 'chevrondown' : 'chevronup'}
                    onClick={() => setCollapse(preState => !preState)}
                  />
                </div>
              )}
            </TabItem>
          ))
        ) : children ? (
          <TabItem title={children?.props?.name || 'Search'}>
            <div
              className={`collapse-form ${!collapse && 'collapse-form-d-none'}`}
            >
              {children}
            </div>
            {!hideSubmitAndExpand && (
              <div
                style={
                  collapse
                    ? {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderTop: '1px solid #efefef',
                        paddingTop: 10,
                      }
                    : {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }
                }
              >
                {!hideSubmit ? (
                  <Button
                    icon="search"
                    text={t('Search')}
                    type="default"
                    stylingMode="contained"
                    onClick={onSubmit}
                  />
                ) : (
                  <span />
                )}
                <Button
                  type="back"
                  stylingMode="outlined"
                  icon={!collapse ? 'chevrondown' : 'chevronup'}
                  onClick={() => setCollapse(preState => !preState)}
                />
              </div>
            )}
          </TabItem>
        ) : null}
      </TabPanel>
    </div>
  );
}

export default forwardRef(Collapse);
