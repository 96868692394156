import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import React, { createContext, useContext, useState } from 'react';

const FormSearch = React.lazy(() => import('./FormSearch'));
const List = React.lazy(() => import('./List'));

// Create context
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2087_A_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};
const heightGrid = 600;
/**
 * Monthly Sales Detail Inquiry
 *
 * @return {*}
 */
const M2087_A = () => {
  const { t }: any = useDictionary({ programId: '2087.2' });
  const { AmountFormat, QtyFormat, DoubleFormat } = useFormat();
  const [listData, setListData] = useState<any>({});

  // Values share with child component
  const value: any = {
    t,
    listData,
    setListData,
    AmountFormat,
    QtyFormat,
    DoubleFormat,
    heightGrid,
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <FormSearch />
        <List />
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2087_A;
