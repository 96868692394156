/**
 * Update date: 13-06-2023
 * Popup select common code component
 */
import { DataGridFull } from 'app/components/DataGrid';
import useAxios from 'axios-hooks';
import { Button, Popup, TextBox, ScrollView } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import useFormatTime from 'hooks/useFormatTime';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import LoadPanel from 'app/components/LoadPanel';
import { useApp } from 'app';

interface IPopupSelectCommonCode {
  visible?: boolean;
  onHiding?: () => void;
  text?: string;
  onSubmit?: void | any;
  mode?: 'single' | 'multiple';
  codeType?:
    | 'PT'
    | 'MK'
    | 'LC'
    | 'MC'
    | 'SM'
    | 'SC'
    | 'UC'
    | 'AT'
    | 'OG'
    | 'RT'
    | 'SK'
    | 'FT'
    | string;
  title?: string;
  parentCd?: string;
  codeSelected?: Array<String>;
  isShowSearch?: boolean;
}

function PopupSelectCommonCode({
  visible,
  onHiding,
  text,
  onSubmit,
  mode = 'single',
  codeType,
  parentCd = '',
  codeSelected = [],
  isShowSearch = true,
}: IPopupSelectCommonCode) {
  const { themePro }: any = useApp();
  const [codeName, setCodeName] = useState(text || '');
  const dataRef: any = useRef(null);
  const { DateTimeFormat } = useFormatTime();
  const { t } = useDictionary({});

  const [{ data: resData, loading }, fetchData] = useAxios(
    {
      url: '/core/common-code/type',
      method: 'POST',
    },
    {
      manual: true,
      useCache: true,
      autoCancel: true,
    },
  );

  const titlePopup = {
    LC: t('Large Category'),
    MC: t('Medium Category'),
    SC: t('Small Category'),
    UC: t('Sub Category'),
    PT: t('Product Type'),
    CT: t('Container Type'),
    CK: t('Container Kind'),
    DP: t('Deposit Type'),
    DE: t('Deduction Type'),
    PM: t('Payment Method'),
    PV: t('Payment Type'),
    PD: t('Payment Type'),
    BK: t('Bank'),
    UT: t('Takeout Due State'),
    SP: t('Supply Unit Price Group'),
    RT: t('Receiving State'),
    ST: t('Store Type'),
    VT: t('Vendor Type'),
    CU: t('Country'),
    LT: t('Load Type'),
    ZS: t('Zone Move State'),
    MK: t('Maker'),
    AT: t('Alcohol Type'),
    OG: t('Origin'),
    IT: t('Receive Due State'),
    OT: t('Order State'),
    SK: t('Reason'),
    FT: t('Shipping Method'),
    CR: t('Delivery Company Code'),
    DK: t('Dues Kind'),
    DB: t('Dashboard Permission'),
    RC: t('Receivable Group'),
    OA: t('Account Type'),
    PE: t('Deduction Type'),
    SB: t('Billing Type'),
    TB: t('Invoice Issue Company'),
    HI: t('Hub Interlock Setting'),
    CB: t('Product Costing Method'),
    MG: t('Product Margin Group'),
    OC: t('Claim Method'),
    MS: t('Product Status'),
    RD: t('Receive Data Owner'),
    XR: t('Reason'),
    CM: t('Cold Storage Code'),
    FE: t('Address Filter Code'),
    PG: t('PG Company'),
  };
  /**
   * on search
   */
  const onSearch = useCallback(() => {
    fetchData({
      data: { codeName, codeType, parentCd },
    });
  }, [fetchData, codeName, codeType, parentCd]);

  const onOk = () => {
    const selectedRowKeys =
      dataRef?.current?.instance?.option?.()?.selectedRowKeys;
    if (!mode || mode === 'single') {
      if (!selectedRowKeys?.length) {
        onHiding?.();
      } else {
        onSubmit(selectedRowKeys?.[0]);
      }
    }
    if (mode === 'multiple') {
      onSubmit(selectedRowKeys);
    }
  };

  useEffect(() => {
    if (codeType && codeType !== '') {
      onSearch();
    }
  }, [codeType, codeName, onSearch]);

  useEffect(() => {
    if (text) {
      setCodeName(text);
    }
  }, [text]);

  useEffect(() => {
    if (!resData?.data?.length || !codeSelected?.length || mode !== 'multiple')
      return;
    const keySelected = resData?.data?.filter(o =>
      codeSelected?.includes(o?.code),
    );
    setTimeout(() => {
      dataRef?.current?.instance?.selectRows(keySelected);
    }, 500);
  }, [resData]);

  const loadingAll = loading;

  /**
   * render content
   *
   * @return {*} 
   */
  const content = () => {
    return (
      <ScrollView width="100%" height="100%">
        <div style={{ padding: '0 15px' }}>
          <LoadPanel visible={loadingAll} />
          {themePro && (
            <div className="modal-popup-header">
              <span className="title-page">{titlePopup?.[`${codeType}`]}</span>
              <div>
                <Button
                  stylingMode="contained"
                  type="default"
                  text={t('OK')}
                  icon="check"
                  style={{ marginRight: 5 }}
                  onClick={onOk}
                />
                <Button
                  stylingMode="contained"
                  text={t('Cancel')}
                  icon="close"
                  onClick={onHiding}
                />
              </div>
            </div>
          )}
          {isShowSearch && (
            <div
              style={
                themePro
                  ? {
                      padding: 8,
                      backgroundColor: '#fff',
                      borderRadius: 6,
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 20,
                      boxShadow:
                        '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                    }
                  : {
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 20,
                    }
              }
            >
              <TextBox
                width={themePro ? 400 : '40vw'}
                defaultValue={codeName}
                value={codeName}
                onValueChanged={e => setCodeName(e?.value)}
                onEnterKey={onSearch}
                style={{ marginRight: 10 }}
              />
              <Button icon="search" text={t('Search')} onClick={onSearch} />
            </div>
          )}

          <DataGridFull
            ref={dataRef}
            dataSource={resData?.data}
            columns={[
              {
                dataField: 'code',
                caption: t('Code'),
                alignment: 'left',
              },
              {
                dataField: 'name',
                caption: t('Code Name'),
                alignment: 'left',
              },
              {
                dataField: 'codeType',
                caption: t('Code Type'),
                alignment: 'left',
                visible: codeType === 'PE',
              },
              {
                dataField: 'note',
                caption: t('Note'),
                alignment: 'left',
              },
              {
                dataField: 'createdDate',
                caption: t('Regist date time'),
                alignment: 'left',
                dataType: 'date',
                format: DateTimeFormat,
              },
            ]}
            options={{
              // keyExpr: 'code',
              onRowDblClick: onOk,
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift({
                  location: 'before',
                  template: 'totalCount',
                });
              },
              selection: {
                mode: mode,
              },
              height: themePro
                ? 'calc(100vh - 200px)'
                : isMobile
                ? 'calc(80vh - 200px)'
                : 'calc(70vh - 200px)',
              columnAutoWidth: true,
            }}
          />
          {!themePro && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 20,
              }}
            >
              <Button icon="save" text={t('OK')} onClick={onOk} />
              <Button icon="close" text={t('Cancel')} onClick={onHiding} />
            </div>
          )}
        </div>
      </ScrollView>
    );
  };

  return (
    <Popup
      className="modal-content-popup"
      visible={visible}
      onHiding={onHiding}
      title={titlePopup?.[`${codeType}`]}
      contentRender={content}
      height={themePro ? '100vh' : isMobile ? '80vh' : '70vh'}
      width={isMobile ? '96%' : '60vw'}
      position={{
        boundaryOffset: { x: undefined, y: 50 },
      }}
    />
  );
}

export default PopupSelectCommonCode;
