/**
 * Update date: 09-06-2023
 * Dynamic menu component
 */
import useAxios from 'axios-hooks';
import { TextBox } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import {
  iconBookmark,
  iconDashboard,
  iconGuide,
  iconLastused,
  iconSearch,
} from 'images';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useNavigation } from '.';
import { LayoutContext } from '../Layout';
import LoadPanel from '../LoadPanel';
const classes: any = {
  navLinkText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subMenu: {
    color: '#667085',
  },
  active: {
    backgroundColor: '#344054',
    borderRadius: 6,
    color: '#fff',
  },
  icon: {
    marginRight: 10,
    width: 15,
    height: 15,
  },
  iconArrow: {
    color: '#667085',
    fontSize: 22,
  },
  iconCoreMenu: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  name: {
    display: 'block !important',
    flex: 1,
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const DynamicMenuDesign = () => {
  const context: any = useNavigation();
  const {
    subMenuSeleted,
    setSubMenuSeleted,
    setListProgram,
    coreMenuSeleted,
    setCoreMenuSeleted,
    setHiddenProgramMenu,
  } = context;
  const layoutContext: any = useContext(LayoutContext);
  const { fullMenu, mainMenu, collapse } = layoutContext;
  const { t } = useDictionary({});
  const history = useHistory();
  // const [searchInput, setSearchInput] = useState('');

  // const myPagePreference = JSON.parse(
  //   localStorage.getItem('myPagePreference') || '{}',
  // );

  const [{ loading: loadingLastUsed }, refetchLastUsed] = useAxios(
    {
      url: 'user/user/program-history',
      method: 'GET',
    },
    {
      manual: true,
    },
  );
  const [{ loading: loadingBookmark }, refetchBookmark] = useAxios(
    {
      url: 'user/user/program-bookmark',
      method: 'GET',
    },
    {
      manual: true,
    },
  );

  /**
   * on last used
   *
   */
  const handleLastUsed = async () => {
    setHiddenProgramMenu(false);
    const res: any = await refetchLastUsed();
    setListProgram({
      subMenu: t('Last Used Menu'),
      list: res?.data?.data || [],
    });
  };
  /**
   * on book mark
   *
   */
  const handleBookmark = async () => {
    setHiddenProgramMenu(false);
    const res: any = await refetchBookmark();
    setListProgram({
      subMenu: t('Bookmark'),
      list: (res?.data?.data || [])?.sort((a, b) =>
        a?.programId >= b?.programId ? 1 : -1,
      ),
    });
  };

  /**
   * on search program
   *
   */
  const handleSearchProgram = keySearch => {
    setHiddenProgramMenu(false);
    setSubMenuSeleted({
      selected: '',
      parentId: '',
    });
    const programMenus = fullMenu
      ?.filter(
        o =>
          o?.programName?.toLowerCase()?.includes(keySearch?.toLowerCase()) ||
          o?.programId?.toLowerCase()?.includes(keySearch?.toLowerCase()),
      )
      ?.sort((a, b) => (a?.programId >= b?.programId ? 1 : -1));

    setListProgram({
      subMenu:
        t('Search Program') +
        ` " ${keySearch} (${programMenus.length} ` +
        t('result') +
        ')',
      list: programMenus,
    });
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (myPagePreference?.userId) {
  //       const lastUsedMenu = JSON.parse(
  //         localStorage.getItem('lastUsedMenu') || '{}',
  //       );
  //       if (
  //         !lastUsedMenu?.list ||
  //         lastUsedMenu?.userId !== myPagePreference?.userId
  //       ) {
  //         const res: any = await refetchLastUsed({});
  //         setListLastUsed((res?.data?.data || []).slice(0, 1));
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [myPagePreference?.userId]);

  return (
    <>
      <LoadPanel visible={loadingLastUsed || loadingBookmark} />
      <ul style={!collapse ? { padding: 16 } : { padding: '16px 0' }}>
        {collapse ? (
          <div
            className={`nav-link-core ${collapse && 'nav-link-core-collapse'}`}
            onClick={() => layoutContext.toggleSidebar()}
          >
            <div style={classes.navLinkText} className="custom-color-white">
              <img
                style={Object.assign({}, classes.icon, {
                  width: 20,
                  height: 20,
                })}
                src={iconSearch}
                alt="icon"
              />
            </div>
          </div>
        ) : (
          <div className="input-search-menu">
            <TextBox
              onInput={(e: any) => {
                handleSearchProgram(e?.event?.target?.value);
              }}
              onEnterKey={e => {
                const value = e?.component?.option?.('text');
                if (value) handleSearchProgram(value);
              }}
              buttons={[
                {
                  location: 'after',
                  name: 'search',
                  options: {
                    icon: 'search',
                    onClick: e => {
                      const eInput: any = e?.element
                        ?.closest?.('.dx-texteditor')
                        ?.querySelector?.('input[type=text]');
                      if (eInput?.value) handleSearchProgram(eInput?.value);
                    },
                  },
                },
              ]}
            />
          </div>
        )}
        <div
          className={`nav-link-core ${collapse && 'nav-link-core-collapse'}`}
          onClick={() => history.push('/103/1032/1032.1')}
        >
          <div style={classes.navLinkText} className="custom-color-white">
            <img
              style={Object.assign({}, classes.icon, {
                width: 20,
                height: 20,
              })}
              src={iconDashboard}
              alt="icon"
            />
            {!collapse && <span style={classes.name}>{t('Dashboard')}</span>}
          </div>
        </div>
        <div
          className={`nav-link-core ${collapse && 'nav-link-core-collapse'}`}
          onClick={() => history.push('/100/1001/1001.1')}
        >
          <div style={classes.navLinkText} className="custom-color-white">
            <img
              style={Object.assign({}, classes.icon, {
                width: 20,
                height: 20,
              })}
              src={iconGuide}
              alt="icon"
            />
            {!collapse && <span style={classes.name}>{t('Guide Screen')}</span>}
          </div>
        </div>
        <div
          className={`nav-link-core ${collapse && 'nav-link-core-collapse'}`}
          onClick={() => {
            if (collapse) layoutContext.toggleSidebar();
            handleLastUsed();
          }}
        >
          <div style={classes.navLinkText} className="custom-color-white">
            <img
              style={Object.assign({}, classes.icon, {
                width: 20,
                height: 20,
              })}
              src={iconLastused}
              alt="icon"
            />
            {!collapse && (
              <span style={classes.name}>{t('Last Used Menu')}</span>
            )}
          </div>
        </div>
        <div
          className={`nav-link-core ${collapse && 'nav-link-core-collapse'}`}
          onClick={() => {
            if (collapse) layoutContext.toggleSidebar();
            handleBookmark();
          }}
        >
          <div style={classes.navLinkText} className="custom-color-white">
            <img
              style={Object.assign({}, classes.icon, {
                width: 20,
                height: 20,
              })}
              src={iconBookmark}
              alt="icon"
            />
            {!collapse && <span style={classes.name}>{t('Bookmark')}</span>}
          </div>
        </div>
        {mainMenu?.map((o, i) => (
          <div key={i}>
            <div
              className={`nav-link-core ${
                collapse && 'nav-link-core-collapse'
              }`}
              onClick={() => {
                if (collapse) {
                  layoutContext.toggleSidebar();
                } else {
                  setCoreMenuSeleted(preState => {
                    if (preState.some(some => some === o?.programId)) {
                      return [...preState].filter(
                        filter => filter !== o?.programId,
                      );
                    }
                    return preState.concat(o?.programId);
                  });
                }
              }}
            >
              <div style={classes.navLinkText} className="custom-color-white">
                {o.programIcon ? (
                  <img
                    style={Object.assign({}, classes.icon, {
                      width: 20,
                      height: 20,
                    })}
                    src={o.programIcon}
                    alt="icon"
                  />
                ) : (
                  <i className={`icon-ring`} style={classes.icon} />
                )}
                {!collapse && (
                  <span style={classes.name}>{t(o?.programName)}</span>
                )}
              </div>
              {!collapse && (
                <i
                  className={
                    coreMenuSeleted.some(some => some === o?.programId)
                      ? 'dx-icon-chevronup'
                      : 'dx-icon-chevrondown'
                  }
                  style={Object.assign(
                    {},
                    classes.iconArrow,
                    classes.iconCoreMenu,
                  )}
                />
              )}
            </div>
            <div style={collapse ? { display: 'none' } : {}}>
              <div
                className="nav-link-sub"
                style={
                  coreMenuSeleted.some(some => some === o?.programId)
                    ? { maxHeight: 1000 }
                    : { maxHeight: 0, overflow: 'hidden' }
                }
              >
                {o?.children?.map((f, j) => (
                  <div
                    style={Object.assign(
                      {},
                      classes.subMenu,
                      subMenuSeleted?.selected === f?.programId &&
                        classes.active,
                    )}
                    className="nav-link-sub-item"
                    key={j}
                    onClick={() => {
                      setSubMenuSeleted(preState => {
                        // if (preState?.selected === f?.programId) {
                        //   setHiddenProgramMenu(true);
                        //   return {
                        //     selected: '',
                        //     parentId: '',
                        //   };
                        // }
                        setHiddenProgramMenu(false);
                        return {
                          selected: f?.programId,
                          parentId: o?.programId,
                        };
                      });
                      setListProgram({
                        subMenu: t(f?.programName) || '',
                        list: f?.children || [],
                      });
                    }}
                  >
                    <span style={classes.name}>{t(f?.programName)}</span>
                    <i
                      className={'dx-icon-spinnext'}
                      style={Object.assign(
                        {},
                        classes.iconArrow,
                        subMenuSeleted?.selected === f?.programId && {
                          color: '#fff',
                        },
                      )}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </ul>
    </>
  );
};

export default DynamicMenuDesign;
