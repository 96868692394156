/**
 * Update date: 15-06-2023
 * Screen 2087.9
 */
import React, { useState, useContext, createContext, useEffect } from 'react';
import LoadPanel from 'app/components/LoadPanel';
import { useProductType } from 'hooks/useProductType';
import FormSearch from './FormSearch';
import useDictionary from 'hooks/useDictionary';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import useAxios from 'axios-hooks';
import { notification } from 'utils/notification';
import { dateFormatStr } from 'utils/format';
import GridByDate from './ByDate';
import GridByMonth from './ByMonth';
import GridByYear from './ByYear';

const salesByDate = '/ecommerce/m2087_9/get-by-date';
const salesByMonth = '/ecommerce/m2087_9/get-by-month';
const salesByYear = '/ecommerce/m2087_9/get-by-year';
// Create context
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2087_9';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2087_9 = () => {
  // create state
  const { productTpPermission, loadingProductTpPermission }: any =
    useProductType();
  const { t }: any = useDictionary({ programId: '2087.9' });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataByDate, setDataByDate] = useState<any>({});
  const [dataByMonth, setDataByMonth] = useState<any>({});
  const [dataByYear, setDataByYear] = useState<any>({});
  const [params, setParams] = useState<any>({
    fromDate: new Date(),
    endDate: new Date(),
    productType: '',
    dataList: {
      productType: [],
    },
  });

  const [{ loading }, fetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  // Set product type of user to state
  useEffect(() => {
    if (!productTpPermission?.length) return;
    setParams(preState => ({
      ...preState,
      dataList: {
        ...preState?.dataList,
        productType: productTpPermission,
      },
      isReadOnlyProductTp: productTpPermission?.length > 0,
    }));
  }, [productTpPermission]);

  /**
   * on change tab
   *
   * @param {*} e
   */
  const onSelectedIndexChange = e => {
    setSelectedIndex(e);
  };

  /**
   * on submit
   *
   */
  const onSubmit = async () => {
    const res = await fetchData({
      url:
        selectedIndex === 0
          ? salesByDate
          : selectedIndex === 1
          ? salesByMonth
          : salesByYear,
      method: 'POST',
      data: {
        fromDate: dateFormatStr(params?.fromDate),
        endDate: dateFormatStr(params?.endDate),
        productTypes: params?.dataList?.productType?.map(o => o?.code),
      },
    });
    if (res?.data?.status === '200') {
      switch (selectedIndex) {
        case 0:
          setDataByDate(res?.data?.data || {});
          break;
        case 1:
          setDataByMonth(res?.data?.data || {});
          break;
        case 2:
          setDataByYear(res?.data?.data || {});
          break;
        default:
          break;
      }
    } else {
      notification({ res });
    }
  };

  const value: any = {
    t,
    params,
    setParams,
    onSubmit,
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loading || loadingProductTpPermission} />
        <FormSearch />
        <TabPanel
          defaultSelectedIndex={selectedIndex}
          selectedIndex={selectedIndex}
          onSelectedIndexChange={onSelectedIndexChange}
          className="bg-white"
        >
          <Item title={t('By Date')}>
            <GridByDate data={dataByDate} t={t} />
          </Item>
          <Item title={t('By Month')}>
            <GridByMonth data={dataByMonth} t={t} />
          </Item>
          <Item title={t('By Year')}>
            <GridByYear data={dataByYear} t={t} />
          </Item>
        </TabPanel>
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2087_9;
