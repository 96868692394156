/**
 * Update date: 08-06-2023
 * Screen 1011.1
 */
import React, { createContext, useContext, useState } from 'react';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import { useHistory } from 'react-router-dom';
import { tabLabelERP } from './routerUrl';
import './styles.scss';

const Tab1 = React.lazy(() => import('./Tab1'));
const Tab2 = React.lazy(() => import('./Tab2'));
const Tab3 = React.lazy(() => import('./Tab3'));
const Tab4 = React.lazy(() => import('./Tab4'));
const Tab5 = React.lazy(() => import('./Tab5'));
const Tab6 = React.lazy(() => import('./Tab6'));
const Tab7 = React.lazy(() => import('./Tab7'));
const Tab8 = React.lazy(() => import('./Tab8'));
/**
 * create context
 */
const WorkflowChartERPContext = createContext({
  emptyBottleDepositListData: [],
});
WorkflowChartERPContext.displayName = 'WorkflowChartERPContext';
export const useWorkflowChartERP = () => {
  return useContext(WorkflowChartERPContext);
};

export default function WorkflowChartERP() {
  const { t } = useDictionary({});
  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tabLabel, setTabLabel] = useState({});
  const onSelectedIndexChange = e => {
    setSelectedIndex(e);
  };

  /**
   * render content
   *
   * @param {*} key
   * @return {*}
   */
  const getContent = key => {
    switch (`${key}`) {
      case '1':
        return <Tab1 />;
      case '2':
        return <Tab2 />;
      case '3':
        return <Tab3 />;
      case '4':
        return <Tab4 />;
      case '5':
        return <Tab5 />;
      case '6':
        return <Tab6 />;
      case '7':
        return <Tab7 />;
      case '8':
        return <Tab8 />;
      default:
        return null;
    }
  };

  const value: any = {
    t,
    history,
  };

  return (
    <WorkflowChartERPContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={false} />
        <TabPanel
          loop
          animationEnabled
          swipeEnabled
          deferRendering={false}
          repaintChangesOnly
          showNavButtons
          scrollingEnabled
          scrollByContent
          selectedIndex={selectedIndex}
          onSelectedIndexChange={onSelectedIndexChange}
          onContentReady={() => {
            setTabLabel(tabLabelERP);
          }}
          className="bg-white"
        >
          {Object.keys(tabLabel)?.map((key: any) => (
            <Item title={t(tabLabel[key])} key={`tab-${key}`}>
              {getContent(key)}
            </Item>
          ))}
        </TabPanel>
      </React.Suspense>
    </WorkflowChartERPContext.Provider>
  );
}
