import { Form, ScrollView } from 'devextreme-react';
import ResponsiveBox, {
  Col,
  Item,
  Location,
  Row,
} from 'devextreme-react/responsive-box';
import useDictionary from 'hooks/useDictionary';
import { createContext, useContext } from 'react';
import { ItemView } from './ItemView';

const ModuleContext = createContext({});
ModuleContext.displayName = 'NotificationContext';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

export const Notification = () => {
  const { t } = useDictionary({});
  const dataTest = [
    {
      image:
        'https://s3-alpha-sig.figma.com/img/81da/3289/c5481934b8a3355aa4af9a3107310100?Expires=1665360000&Signature=NR8TgkUVdMZPRH3dIF1EHXix8qRD41O-tvv17LQYO67-FnMQJqHNMEw2sVJfjbAkFgHZlIMFqpIqKbl~Dcbtp7wBVdcZZoGrqQ5IFbzb7GoHsJ-7eZhSOFXqUClyIgVEQ~mDmEA7LSCdQmmrdoeyM1lOZXjHkdJgyBBHXapnfSzFlex02tXUk1mggk2wQeDpprqSzd6lQH2oE-cK3f-L-z39a-TJ220Fq6AFSo7UoM2lgwYaF2m0027jKU9vasK5O2G1UCfVnhHVoL15epi95f7mIKxjS0qbr28yrmuk-McYmkS4anx8tgtfhhpU9sT5OCeLk5~hHyy1pK8411YyTA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
      name: 'Marvin McKinney',
      time: '1 mins ago',
      title: 'Receiving registration without ordering',
      content:
        'Bill ID: SMDC-PH-220430-27664 Date: 2022-04-30 Total: 50.000.000',
      status: '1',
    },
    {
      image:
        'https://s3-alpha-sig.figma.com/img/81da/3289/c5481934b8a3355aa4af9a3107310100?Expires=1665360000&Signature=NR8TgkUVdMZPRH3dIF1EHXix8qRD41O-tvv17LQYO67-FnMQJqHNMEw2sVJfjbAkFgHZlIMFqpIqKbl~Dcbtp7wBVdcZZoGrqQ5IFbzb7GoHsJ-7eZhSOFXqUClyIgVEQ~mDmEA7LSCdQmmrdoeyM1lOZXjHkdJgyBBHXapnfSzFlex02tXUk1mggk2wQeDpprqSzd6lQH2oE-cK3f-L-z39a-TJ220Fq6AFSo7UoM2lgwYaF2m0027jKU9vasK5O2G1UCfVnhHVoL15epi95f7mIKxjS0qbr28yrmuk-McYmkS4anx8tgtfhhpU9sT5OCeLk5~hHyy1pK8411YyTA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
      name: 'Marvin McKinney',
      time: '1 mins ago',
      title: 'Receiving registration without ordering',
      content:
        'Bill ID: SMDC-PH-220430-27664 Date: 2022-04-30 Total: 50.000.000',
      status: '0',
    },
  ];

  function screen(width) {
    return width < 960 ? 'sm' : 'lg';
  }

  const value: any = {
    t,
  };
  return (
    <ModuleContext.Provider value={value}>
      <div className="popover-notification">
        <div className="title-page" style={{ marginTop: 0 }}>
          {t('Notifications')}
        </div>
        <ResponsiveBox singleColumnScreen="sm" screenByWidth={screen}>
          <Row ratio={1}></Row>
          <Col ratio={5}></Col>
          <Col ratio={2}></Col>
          <Item>
            <Location row={0} col={0} screen="lg"></Location>
            <Location row={1} col={0} screen="sm"></Location>
            <div className="body-padding-white" style={{ marginRight: 20 }}>
              <ScrollView height={'calc(100vh - 180px)'}>
                {dataTest?.map((o, i) => (
                  <ItemView data={o} key={i} cursor />
                ))}
              </ScrollView>
            </div>
          </Item>
          <Item>
            <Location row={0} col={1} screen="lg"></Location>
            <Location row={0} col={0} screen="sm"></Location>
            <div>
              <Form
                labelLocation="top"
                showColonAfterLabel={false}
                formData={null}
                className="form-data-order"
                items={[
                  {
                    itemType: 'group',
                    caption: t('Search'),
                    colCount: 3,
                    cssClass: 'body-padding-white group-flex-end mb-10',
                    items: [
                      {
                        colSpan: 2,
                        editorType: 'dxTextBox',
                        dataField: 'search',
                        label: {
                          text: t('Enter keyword'),
                        },
                      },
                      {
                        itemType: 'button',
                        buttonOptions: {
                          text: t('Search'),
                          icon: 'search',
                          type: 'default',
                          width: '100%',
                        },
                      },
                    ],
                  },
                  {
                    itemType: 'group',
                    caption: t('Filter'),
                    cssClass: 'body-padding-white group-flex-end mb-10',
                    items: [
                      {
                        cssClass: 'form-label-bold',
                        label: { text: t('Type') },
                      },
                      {
                        dataField: 'pending',
                        label: {
                          visible: false,
                        },
                        editorType: 'dxCheckBox',
                        editorOptions: {
                          text: t('Pending Status') + '( 6)',
                        },
                      },
                      {
                        dataField: '',
                        label: {
                          visible: false,
                        },
                        editorType: 'dxCheckBox',
                        editorOptions: {
                          text: t('Errors') + '( 6)',
                        },
                      },
                      {
                        dataField: '',
                        label: {
                          visible: false,
                        },
                        editorType: 'dxCheckBox',
                        editorOptions: {
                          text: t('Claims') + '( 6)',
                        },
                      },
                      {
                        cssClass: 'form-label-bold',
                        label: { text: t('Status') },
                        colSpan: 2,
                        dataField: 'taskType',
                        editorType: 'dxRadioGroup',
                        editorOptions: {
                          valueExpr: 'value',
                          items: [
                            { text: t('All'), value: 0 },
                            { text: t('Unred'), value: 1 },
                          ],
                        },
                      },
                    ],
                  },
                ]}
                colCount={1}
              />
            </div>
          </Item>
        </ResponsiveBox>
        <div
          style={{ display: 'grid', gridTemplateColumns: '5fr 2fr', gap: 20 }}
        >
          <div></div>
        </div>
      </div>
    </ModuleContext.Provider>
  );
};
